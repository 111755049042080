import { get, transform } from "lodash"
import { createSelector } from "reselect"

import { IDevice } from "api/interfaces/IDevice"
import {
  allDevicesSelector,
  allDeviceTelemetrySelector,
  allPlacesSelector,
  allTravelSheetsSelector,
  allUtilizationsSelector,
  allZonesSelector
} from "api/reduxResourcesSelectors"
import { resolveTimePeriod } from "components/TimePeriodSelector"
import { TState } from "tracking/store/store"
import { TObject } from "utils"

import { TrackingPageState, UtilizationOptionValueType } from "./types"
import { parseOptionValue } from "./module"
import { DEVICE_KINDS } from "models/device"

export const trackingPageSelector = (state: TState): TrackingPageState => get(state, "pages.trackingPage")

export const filteredDeviceIdsSelector = createSelector(
  trackingPageSelector,
  ({ filteredDeviceIds }) => filteredDeviceIds
)

export const deviceFilterSelector = createSelector(
  trackingPageSelector,
  ({ deviceFilter }) => deviceFilter
)

export const deviceSelector = (deviceId: string) => createSelector(
  allDevicesSelector,
  allDeviceTelemetrySelector,
  (devices, telemetry) => {
    const device = devices[deviceId]
    if (device) return { ...device, telemetry: telemetry[deviceId] }
  }
)

export const deviceTelemetrySelector = (deviceId: string) => createSelector(
  allDeviceTelemetrySelector,
  deviceTelemetry => deviceTelemetry[deviceId]
)

export const zoneFilterSelector = createSelector(
  trackingPageSelector,
  ({ zoneFilter }) => zoneFilter
)

export const openModalSelector = createSelector(
  trackingPageSelector,
  ({ openModal }) => openModal
)

export const placeFilterSelector = createSelector(
  trackingPageSelector,
  ({ placeFilter }) => placeFilter
)

export const filteredDevicesSelector = createSelector(
  allDevicesSelector,
  filteredDeviceIdsSelector,
  (devices, filteredDeviceIds) => transform(filteredDeviceIds, (acc, deviceId) => {
    if (!devices[deviceId]) return
    acc[deviceId] = devices[deviceId]
  }, {} as TObject<IDevice>)
)

export const filteredDevicesListSelector = createSelector(
  allDevicesSelector,
  filteredDeviceIdsSelector,
  (devices, filteredDeviceIds) => transform(filteredDeviceIds, (acc, deviceId) => {
    if (!devices[deviceId]) return
    acc.push(devices[deviceId])
  }, [] as IDevice[])
)

export const selectionSelector = createSelector(
  trackingPageSelector,
  ({ selection }) => selection
)

export const placeSelector = (placeId: string) => createSelector(
  allPlacesSelector,
  places => places[placeId]
)

export const zoneSelector = (zoneId: string) => createSelector(
  allZonesSelector,
  zones => zones[zoneId]
)

export const travelSheetSidebarVisibilitySelector = createSelector(
  trackingPageSelector,
  ({ isTravelSheetSidebarVisible }) => isTravelSheetSidebarVisible
)

export const selectedUtilizationValueSelector = createSelector(
  trackingPageSelector,
  ({ selectedUtilization }) => selectedUtilization
)

export const selectedDeviceIdsSelector = createSelector(
  trackingPageSelector,
  ({ selectedDeviceIds }) => selectedDeviceIds
)

export const selectedUtilizationSelector = createSelector(
  selectedUtilizationValueSelector,
  allTravelSheetsSelector,
  allUtilizationsSelector,
  (selectedUtilization, travelSheets, utilizations) => {
    if (!selectedUtilization) return null

    const { type, id } = parseOptionValue(selectedUtilization)

    switch (type) {
      case UtilizationOptionValueType.TRAVEL_SHEET: return { type: UtilizationOptionValueType.TRAVEL_SHEET, value: travelSheets[id] }
      case UtilizationOptionValueType.DEVICE_UTILIZATION: return { type: UtilizationOptionValueType.DEVICE_UTILIZATION, value: Object.values(utilizations).filter(utilization => utilization.deviceId === id) }
      case UtilizationOptionValueType.ZONE_UTILIZATION: return { type: UtilizationOptionValueType.ZONE_UTILIZATION, value: Object.values(utilizations).filter(utilization => utilization.zoneId === id) }
    }
  }
)

export const timePeriodSelector = createSelector(
  trackingPageSelector,
  ({ timePeriod }) => ({
    endTime: new Date(timePeriod.endTime),
    startTime: new Date(timePeriod.startTime),
    value: timePeriod.value
  })
)

export const resolvedTimePeriodSelector = createSelector(
  timePeriodSelector,
  resolveTimePeriod
)

export const hasHarvestDevicesSelector = createSelector(
  allDevicesSelector,
  devices => Object.values(devices).some(({ kind }) => kind === DEVICE_KINDS.EHI)
)

export const utilizationsForDevicesSelector = createSelector(
  allUtilizationsSelector,
  utilizations => Object.values(utilizations).filter(utilization => utilization.deviceId != null)
)

export const utilizationsForZonesSelector = createSelector(
  allUtilizationsSelector,
  utilizations => Object.values(utilizations).filter(utilization => utilization.zoneId != null)
)
