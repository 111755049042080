import { IDeviceTravelSheet } from "api/interfaces/IDevice"
import { IUnpersistedEventTrigger } from "api/interfaces/IEvent"
import { IUnpersistedPlace } from "api/interfaces/IPlace"
import { IDeviceUtilization, IZoneUtilization } from "api/interfaces/IUtilization"
import { IUnpersistedZone } from "api/interfaces/IZone"

import { ApiAction, ApiActionMeta, ApiActionType, ResourceKey, ResourceType } from "./types"

const resourceTypeKeys = {
  [ResourceType.CASHBOX_WAREHOUSE]: ResourceKey.CASHBOX_WAREHOUSES,
  [ResourceType.DEVICE]: ResourceKey.DEVICES,
  [ResourceType.DEVICE_GROUP]: ResourceKey.DEVICE_GROUPS,
  [ResourceType.EVENT_TRIGGER]: ResourceKey.EVENT_TRIGGERS,
  [ResourceType.PLACE]: ResourceKey.PLACES,
  [ResourceType.SENSOR]: ResourceKey.SENSORS,
  [ResourceType.USER]: ResourceKey.USERS,
  [ResourceType.ZONE]: ResourceKey.ZONES
}

const getActionCreator = <T = object>(resourceType: ResourceType, resourceAction: string, resourceKey?: ResourceKey) => (meta?: ApiActionMeta<T>): ApiAction => ({
  meta: meta || {},
  resourceAction,
  resourceType,
  resourceKey: resourceKey || resourceTypeKeys[resourceType],
  type: ApiActionType.START
})

const getActionCreatorWithPayload = <T>(resourceType: ResourceType, resourceAction: string, resourceKey?: ResourceKey) => (payload: T, meta?: ApiActionMeta): ApiAction => ({
  meta: meta || {},
  resourceAction,
  resourceType,
  resourceKey: resourceKey || resourceTypeKeys[resourceType],
  type: ApiActionType.START,
  payload
})

export const CashboxWarehouse = {
  all: getActionCreator(ResourceType.CASHBOX_WAREHOUSE, "all"),
  productBalances: getActionCreator(ResourceType.CASHBOX_WAREHOUSE, "productBalances", ResourceKey.CASHBOX_PRODUCT_BALANCES)
}

export const Device = {
  all: getActionCreator(ResourceType.DEVICE, "all"),
  currentTelemetry: getActionCreator(ResourceType.DEVICE, "currentTelemetry", ResourceKey.DEVICE_TELEMETRY),
  harvestSheet: getActionCreator<IDeviceTravelSheet>(ResourceType.DEVICE, "harvestSheet", ResourceKey.TRAVEL_SHEET),
  travelSheet: getActionCreator<IDeviceTravelSheet>(ResourceType.DEVICE, "travelSheet", ResourceKey.TRAVEL_SHEET),
  utilization: getActionCreator<IDeviceUtilization>(ResourceType.DEVICE, "utilization", ResourceKey.UTILIZATIONS)
}

export const DeviceGroup = {
  all: getActionCreator(ResourceType.DEVICE_GROUP, "all")
}

export const EventTrigger = {
  all: getActionCreator(ResourceType.EVENT_TRIGGER, "all"),
  create: getActionCreatorWithPayload<IUnpersistedEventTrigger>(ResourceType.EVENT_TRIGGER, "create"),
  destroy: getActionCreator(ResourceType.EVENT_TRIGGER, "destroy"),
  update: getActionCreatorWithPayload<Partial<IUnpersistedEventTrigger>>(ResourceType.EVENT_TRIGGER, "update")
}

export const Place = {
  all: getActionCreator(ResourceType.PLACE, "all"),
  create: getActionCreatorWithPayload<IUnpersistedPlace>(ResourceType.PLACE, "create"),
  destroy: getActionCreator(ResourceType.PLACE, "destroy"),
  update: getActionCreatorWithPayload<Partial<IUnpersistedPlace>>(ResourceType.PLACE, "update")
}

export const Sensor = {
  all: getActionCreator(ResourceType.SENSOR, "all")
}

export const User = {
  current: getActionCreator(ResourceType.USER, "current")
}

export const Zone = {
  all: getActionCreator(ResourceType.ZONE, "all"),
  create: getActionCreatorWithPayload<IUnpersistedZone>(ResourceType.ZONE, "create"),
  destroy: getActionCreator(ResourceType.ZONE, "destroy"),
  update: getActionCreatorWithPayload<Partial<IUnpersistedZone>>(ResourceType.ZONE, "update"),
  utilization: getActionCreatorWithPayload<IZoneUtilization>(ResourceType.ZONE, "utilization", ResourceKey.UTILIZATIONS)
}
