abstract class Cache {
  public cacheKey: string
  public cache: Record<any, any>

  constructor(cacheKey: string) {
    this.cacheKey = cacheKey
    this.cache = this.readCache()
  }

  get = <T>(key: string): T | void => {
    return this.cache[key]
  }

  set = <T>(key: string, value: T): void => {
    if (this.cache[key] === value) return

    this.cache[key] = value
    this.writeCache()
  }

  remove = (key: string): void => {
    delete this.cache[key]
    this.writeCache()
  }

  clear = (): void => {
    this.cache = {}
    this.clearCache()
  }

  refresh = (): void => {
    this.cache = this.readCache()
  }

  protected abstract clearCache(): void
  protected abstract readCache(): Record<string, any>
  protected abstract writeCache(): void
}

export default Cache
