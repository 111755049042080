import Cache from "./Cache"
import { marshalToJson, unmarshalFromJson } from "./marshalling"

class LocalStorageCache extends Cache {
  protected clearCache() {
    localStorage.removeItem(this.cacheKey)
  }

  protected readCache() {
    const marshalledCache = localStorage.getItem(this.cacheKey)
    return unmarshalFromJson(marshalledCache)
  }

  protected writeCache() {
    localStorage.setItem(
      this.cacheKey,
      marshalToJson(this.cache)
    )
  }
}

export default LocalStorageCache
