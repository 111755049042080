import { AxiosRequestConfig } from "axios"

import { IUnpersistedPlace, IPlace } from "../interfaces/IPlace"
import Resource from "../Resource"

interface Icreate {
  data: IUnpersistedPlace
  options?: AxiosRequestConfig
}

class PlaceResource extends Resource<IPlace> {
  get resourcePath() { return "/api/places" }

  create = ({ data, options }: Icreate): Promise<IPlace> =>
    this.post<IUnpersistedPlace, IPlace>(this.resourcePath, data, options)
}

export default PlaceResource
