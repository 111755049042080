import { useEffect, useState } from "react"

interface FormStateOptions {
  touchAll?: boolean
}

type ValidatorFunction<T, S> = (attribute: S, state: T) => string | undefined
type Validators<T> = { [key in keyof Partial<T>]: ValidatorFunction<T, T[key]> }
export type TouchedState<T> = { [key in keyof Partial<T>]: boolean }
export type ErrorState<T> = { [key in keyof Partial<T>]: string | undefined }

const useFormState = <T>(initialState: T, validators = {} as Validators<T>, options = {} as FormStateOptions) => {
  const [state, setState] = useState(initialState)
  const [errors, setErrors] = useState({} as ErrorState<T>)
  const [touched, setTouched] = useState({} as TouchedState<T>)
  const isValid = Object.values(errors).every(error => error == null)

  const onStateChange = (nextState: Partial<T>) => {
    setState(currentState => ({ ...currentState, ...nextState }))
    handleErrors(nextState)
    handleTouched(Object.keys(nextState))
  }

  const handleErrors = (nextState: Partial<T>) => {
    setErrors(currentErrors => {
      const nextErrors = { ...currentErrors }

      const keys = Object.keys(nextState) as Array<keyof Partial<T>>

      keys.forEach(key => {
        const validator = validators[key]

        if (!validator) return

        const result = validator(nextState[key], state)

        if (result) {
          nextErrors[key] = result
        } else {
          delete nextErrors[key]
        }
      })

      return nextErrors
    })
  }

  const handleTouched = (keys: string[]) => {
    const nextTouched = { ...touched }
    keys.forEach(key => { nextTouched[key] = true })
    setTouched(nextTouched)
  }

  const touchAll = () => {
    const nextTouched = Object.keys(state).reduce((acc, key) => {
      acc[key] = true
      return acc
    }, {} as TouchedState<T>)

    setTouched(nextTouched)
  }

  useEffect(() => {
    handleErrors(state)
    if (options.touchAll) touchAll()
  }, [])

  return {
    errors,
    isValid,
    setState: onStateChange,
    state,
    touched
  }
}

export default useFormState
