import { useState } from "react"

const DEFAULT_THROTTLE_IN_MILLISECONDS = 800

const useAnimateError = (throttleInMilliseconds = DEFAULT_THROTTLE_IN_MILLISECONDS) => {
  const [isAnimatingError, setIsAnimatingError] = useState(false)

  const animateError = () => {
    setIsAnimatingError(isAnimatingError => {
      if (!isAnimatingError) {
        setTimeout(() => setIsAnimatingError(false), throttleInMilliseconds)
      }

      return true
    })
  }

  const getAnimateErrorClassName = className => isAnimatingError
    ? `${className} shake`
    : className

  return [isAnimatingError, animateError, getAnimateErrorClassName]
}

export default useAnimateError
