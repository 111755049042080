import L from "leaflet"

import { IDevice } from "api/interfaces/IDevice"

import { arrow, arrowHighlighted } from "../../icons"
import DeviceTooltip from "./DeviceTooltip"

class DeviceMarker {
  public device: IDevice
  private marker: L.Marker
  private layer: L.FeatureGroup
  private isHidden: boolean = false
  private hasLocation: boolean = false
  private tooltip: DeviceTooltip

  constructor(device: IDevice, layer: L.FeatureGroup) {
    this.device = device
    this.layer = layer
    this.marker = L.marker([0, 0], { icon: arrow, rotationOrigin: "center" }).addTo(layer)
    this.tooltip = new DeviceTooltip(this.marker)
  }

  get position(): L.LatLngExpression | null {
    return this.hasLocation ? this.marker.getLatLng() : null
  }

  delete(): void {
    this.layer.removeLayer(this.marker)
  }

  hide(): this {
    if (this.isHidden) return

    this.layer.removeLayer(this.marker)
    this.isHidden = true

    return this
  }

  show(): this {
    if (!this.isHidden) return

    this.marker.addTo(this.layer)
    this.isHidden = false

    return this
  }

  moveTo(position: L.LatLngExpression): this {
    this.hasLocation = true
    this.marker.setLatLng(position)
    return this
  }

  rotate(angle: number): this {
    this.marker.setRotationAngle(angle)
    return this
  }

  setLabel(label: string): this {
    this.tooltip.text = label
    return this
  }

  highlight(): this {
    this.marker.setIcon(arrowHighlighted)
    this.marker.setZIndexOffset(200000)
    this.tooltip.highlight()
    return this
  }

  unighlight(): this {
    this.marker.setIcon(arrow)
    this.marker.setZIndexOffset(0)
    this.tooltip.unhighlight()
    return this
  }
}

export default DeviceMarker
