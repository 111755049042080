import L from "leaflet"

import { IUtilization } from "api/interfaces/IUtilization"

import { MAP_ENTITY_COLOR_HOVER } from "../constants"

interface IUtilizationLayerEntity {
  polylines: L.Polyline[]
  utilization: IUtilization
  zonePolygon: L.Polygon
}

class UtilizationLayer {
  private layer: L.FeatureGroup
  private map: L.Map
  private entities: { [utilizationId: string]: IUtilizationLayerEntity } = {}

  constructor(map: L.Map) {
    this.map = map
    this.layer = L.featureGroup().addTo(map)
  }

  add(utilization: IUtilization) {
    if (this.entities[utilization.id]) return

    const zonePolygon = L.polygon(utilization.coordinates, { color: MAP_ENTITY_COLOR_HOVER })
      .addTo(this.layer)
      .bringToFront()
    const polylines = []

    utilization.utilizations.forEach(u => (
      u.points.forEach(points =>
        polylines.push(
          L.polyline(points, { color: MAP_ENTITY_COLOR_HOVER })
            .addTo(this.layer)
            .bringToFront()
        )
      )
    ))

    this.entities[utilization.id] = { polylines, utilization, zonePolygon }
  }

  delete(utilizationId: string) {
    const entity = this.entities[utilizationId]

    if (!entity) return

    entity.polylines.forEach(polyline => this.layer.removeLayer(polyline))
    this.layer.removeLayer(entity.zonePolygon)
    delete this.entities[utilizationId]
  }

  deleteAll() {
    for (const entity of Object.values(this.entities)) {
      this.delete(entity.utilization.id)
    }
  }

  panTo(utilizationId: string) {
    const entity = this.entities[utilizationId]

    if (!entity) return

    this.map.fitBounds(entity.zonePolygon.getBounds())

    // Sometimes, a weird bug appears where the zones do not get properly drawn
    // after the map panned to a certain zone.
    for (const entity of Object.values(this.entities)) {
      entity.zonePolygon.redraw()
      entity.polylines.forEach(polyline => polyline.redraw())
    }
  }
}

export default UtilizationLayer
