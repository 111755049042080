import { useEffect, useMemo, useState } from "react"
import { startOfDay, subWeeks } from "date-fns"

import { DeviceGroupResource, DeviceResource } from "api"
import { IDevice } from "api/interfaces/IDevice"
import { IDeviceGroup } from "api/interfaces/IDeviceGroup"
import { resourceArrayToHash, TObject } from "utils"
import { filter, includes, isEmpty } from "lodash"

export interface IReportFormState {
  endTime: Date
  name: string
  kind: string
  reoccurDaily: boolean
  reoccurWeekly: boolean
  reoccurMonthly: boolean
  devices: IDevice[]
  formats: string[]
  startTime: Date
}

export interface IReportFormStateErrors {
  devices?: string
  endTime?: string
  name?: string
  reoccurDaily?: string
  reoccurWeekly?: string
  reoccurMonthly?: string
  startTime?: string
}

export type TReportFormValidator = (state: IReportFormState) => IReportFormStateErrors

const AVAILABLE_DEVICE_KINDS_FOR_REPORT_KINDS = {
  "machine_time_in_zones": ["kfz", "ehi", "mze"],
  "travel_sheet_short": ["kfz", "pze"],
  "travel_sheet_detailled": ["kfz", "pze"]
}

const AVAILABLE_FORMATS_FOR_REPORT_KINDS = {
  "machine_time_in_zones": ["pdf"],
  "travel_sheet_short": ["pdf"],
  "travel_sheet_detailled": ["pdf"]
}

const INITIAL_STATE: IReportFormState = {
  devices: [],
  endTime: new Date(),
  formats: AVAILABLE_FORMATS_FOR_REPORT_KINDS["travel_sheet_short"],
  kind: "travel_sheet_short",
  name: "",
  reoccurDaily: false,
  reoccurWeekly: false,
  reoccurMonthly: false,
  startTime: startOfDay(subWeeks(new Date(), 1))
}

const INITIAL_RESOURCES = {
  devices: [] as IDevice[],
  deviceGroups: {} as TObject<IDeviceGroup>
}

const getIsDeviceSelectable = (kind: string, selectedDevices: IDevice[]) => (device: IDevice) =>
  includes(AVAILABLE_DEVICE_KINDS_FOR_REPORT_KINDS[kind], device.kind) &&
    !includes(selectedDevices, device)


const useReportFormState = (reportFormValidator: TReportFormValidator) => {
  const [resources, setResources] = useState(INITIAL_RESOURCES)
  const [isLoading, setIsLoading] = useState(true)
  const [state, setState] = useState(INITIAL_STATE)
  const selectableDevices = useMemo(() => {
    const isDeviceSelectable = getIsDeviceSelectable(state.kind, state.devices)
    return filter(resources.devices, isDeviceSelectable)
  }, [resources.devices, state.kind, state.devices])
  const errors = useMemo(() => reportFormValidator(state), [state])
  const selectableFormats = AVAILABLE_FORMATS_FOR_REPORT_KINDS[state.kind]
  const onStateChange = (stateSlice: Partial<IReportFormState>) => {
    setState(currentState => ({ ...currentState, ...stateSlice }))
  }

  useEffect(() => {
    const effect = async () => {
      const [devices, deviceGroups] = await Promise.all([DeviceResource.all({ options: { params: { pageSize: 9999 } } }), DeviceGroupResource.all()])
      setResources({ devices, deviceGroups: resourceArrayToHash(deviceGroups) })
      setIsLoading(false)
    }

    effect()
  }, [])

  useEffect(() => {
    setState(currentState => ({
      ...currentState,
      devices: filter(currentState.devices, device => includes(AVAILABLE_DEVICE_KINDS_FOR_REPORT_KINDS[state.kind], device.kind)),
      formats: filter(currentState.formats, format => includes(AVAILABLE_FORMATS_FOR_REPORT_KINDS[state.kind], format))
    }))
  }, [state.kind])

  return {
    devices: resources.devices,
    deviceGroups: resources.deviceGroups,
    errors,
    isLoading,
    isValid: isEmpty(errors),
    selectableDevices,
    selectableFormats,
    setState: onStateChange,
    state
  }
}

export default useReportFormState
