import { Controller } from "stimulus"

class NavigationController extends Controller {
  private navigationLinksTarget: HTMLInputElement
  private collapsedArrowTarget: HTMLInputElement
  private expandedArrowTarget: HTMLInputElement
  private collapsed = true

  static targets = ["navigationLinks", "collapsedArrow", "expandedArrow"]

  toggleNavigation(e: InputEvent) {
    if (this.collapsed) {
      this.collapsed = false
      this.navigationLinksTarget.classList.remove("hidden")
      this.collapsedArrowTarget.classList.remove("hidden")
      this.expandedArrowTarget.classList.add("hidden")
    } else {
      this.collapsed = true
      this.navigationLinksTarget.classList.add("hidden")
      this.collapsedArrowTarget.classList.add("hidden")
      this.expandedArrowTarget.classList.remove("hidden")
    }
  }
}

export default NavigationController
