import { useStoreSubscription } from "hooks"
import { useEffect, useRef, useState } from "react"
import { useStore } from "react-redux"
import { TState } from "tracking/store/store"

const useDebouncedSelector = <TSelected = unknown>(
  selector: (state: TState) => TSelected,
  equalityFn: (left: TSelected, right: TSelected) => boolean,
  debounceTime: number
) => {
  const timeout = useRef<NodeJS.Timeout>()
  const store = useStore()
  const [debouncedState, setDebouncedState] = useState<TSelected>(() => selector(store.getState()))

  useStoreSubscription(selector, equalityFn, selectorResult => {
    clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      setDebouncedState(selectorResult)
    }, debounceTime)
  })

  useEffect(() => () => {
    clearTimeout(timeout.current)
  }, [selector, equalityFn, debounceTime])

  return debouncedState
}

export default useDebouncedSelector
