export const setIsEditing = (isEditing: boolean) => ({
  type: "map/setIsEditing",
  payload: isEditing
})

export const deleteTravelSheet = (deviceId: string) => ({
  type: "resources/deleteTravelSheet",
  payload: deviceId
})

export const deleteDeviceUtilizationForDevice = (deviceId: string) => ({
  type: "resources/deleteDeviceUtilizationForDevice",
  payload: deviceId
})

export const deleteZoneUtilizationForZone = (deviceId: string) => ({
  type: "resources/deleteZoneUtilizationForZone",
  payload: deviceId
})

export const deleteDeviceUtilization = (id: string) => ({
  type: "resources/deleteDeviceUtilization",
  payload: id
})
