import { AxiosRequestConfig } from "axios"
import { merge } from "lodash"

import { IDevice, IDeviceTravelSheet } from "api/interfaces/IDevice"
import { IUtilization, IDeviceUtilization, IRawUtilization } from "api/interfaces/IUtilization"
import { isEmpty } from "utils"

import { humanizeTripKeys, transformUtilizationDetails } from "../helpers"
import { ITelemetry } from "../interfaces/ITelemetry"
import { ITravelSheet, IRawTravelSheet } from "../interfaces/ITravelSheet"
import Resource from "../Resource"

interface IcurrentTelemetry {
  deviceIds?: string[]
  options?: AxiosRequestConfig
  updatedAtGt?: string
}

class DeviceResource extends Resource<IDevice> {
  get resourcePath() { return "/api/devices" }

  currentTelemetry = ({ updatedAtGt, deviceIds, options }: IcurrentTelemetry): Promise<ITelemetry[]> =>
    this.get(
      `${this.resourcePath}/current_telemetry`,
      merge({}, options, {
        params: {
          ...(updatedAtGt && { updatedAtGt }),
          ...(!isEmpty(deviceIds) && { ids: deviceIds.join(",") })
        }
      })
    )

  harvestSheet = async ({ deviceId, startTime, endTime }: IDeviceTravelSheet): Promise<ITravelSheet> => {
    const response = await this.get<IRawTravelSheet>(
      `${this.resourcePath}/${deviceId}/harvest_sheet`,
      { params: { startTime, endTime } }
    )
    return humanizeTripKeys(response)
  }

  travelSheet = async ({ deviceId, startTime, endTime, stopThreshold }: IDeviceTravelSheet): Promise<ITravelSheet> => {
    const response = await this.get<IRawTravelSheet>(
      `${this.resourcePath}/${deviceId}/travel_sheet`,
      { params: { startTime, endTime, stopThreshold } }
    )
    return humanizeTripKeys(response)
  }

  utilization = async ({ coordinates, deviceId, startTime, endTime }: IDeviceUtilization): Promise<IUtilization> => {
    const response = await this.post<Object, IRawUtilization>(
      `${this.resourcePath}/${deviceId}/utilization`,
      { coordinates, startTime, endTime }
    )

    return {
      ...response,
      deviceId,
      coordinates: coordinates.map(([lng, lat]) => ({ lat, lng })),
      utilizations: transformUtilizationDetails(response.utilizations)
    } as IUtilization
  }
}

export default DeviceResource
