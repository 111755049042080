import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { ApiAction } from "api/types"

const useFetchResource = (actionCreator: () => ApiAction, effectIds: any[]) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({ isLoading: true, resources: null })
  const loadingPromise = useRef<Promise<any>>()

  const finishLoading = (promise: Promise<any>, resources: any | any[]) => {
    if (promise !== loadingPromise.current) return
    setState({ isLoading: false, resources })
  }

  useEffect(() => {
    setState({ isLoading: true, resources: null })
    const promise = loadingPromise.current = dispatch(actionCreator()).responsePromise
    promise.then(resources => finishLoading(promise, resources))
  }, effectIds)

  return Object.freeze(state)
}

export default useFetchResource
