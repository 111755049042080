import { Controller } from "stimulus"

class FTPReportHTMLController extends Controller {
  connect() {
    // Achieve bootstrap look for pre-generated reports.
    const tables = document.getElementById("report").getElementsByTagName("table")

    for (const table of tables) {
      table.classList.add("table-classic-report")
      table.removeAttribute("width")
      table.removeAttribute("cellpadding")
      table.removeAttribute("cellspacing")
      table.removeAttribute("border")
    }
  }
}

export default FTPReportHTMLController
