import { debounce } from "lodash"
import { useCallback, useEffect } from "react"

const useOnResize = (callback: () => void, dependencies: any[]) => {
  const memoizedCallback = useCallback(debounce(callback, 300), dependencies)

  useEffect(() => {
    window.addEventListener("resize", memoizedCallback)
    return () => window.removeEventListener("resize", memoizedCallback)
  }, [memoizedCallback])
}

export default useOnResize
