import { compact } from "lodash"

type TEventHandlers = Map<string, Set<Function>>
type TMapEventHandlers = Map<L.Map, TEventHandlers>
type TZoneEvent = "click"
type TPlaceEvent = "click"
type TSectionEvent = "click" | "mouseover" | "mouseout"
type TTravelSheetEvent = "open"

export const getZoneEventName = (event: TZoneEvent, id?: string) => compact(["zones", event, id]).join("/")
export const getPlaceEventName = (event: TPlaceEvent, id?: string) => compact(["place", event, id]).join("/")
export const getSectionEventName = (event: TSectionEvent, id?: string) => compact(["section", event, id]).join("/")
export const getTravelSheetEventName = (event: TTravelSheetEvent, id?: string) => compact(["travelSheet", event, id]).join("/")

const eventHandlers: TMapEventHandlers = new Map()

const eventHandlersForEvent = (map: L.Map, eventName: string): Set<Function> => {
  let handlersForMap = eventHandlers.get(map)

  if (!handlersForMap) {
    handlersForMap = new Map()
    eventHandlers.set(map, handlersForMap)
  }

  let handlers = handlersForMap.get(eventName)

  if (!handlers) {
    handlers = new Set()
    handlersForMap.set(eventName, handlers)
  }

  return handlers
}

export const onMapEvent = (map: L.Map, eventName: string, handler: Function) => {
  const handlers = eventHandlersForEvent(map, eventName)

  handlers.add(handler)

  return () => {
    handlers.delete(handler)
  }
}

export const triggerMapEvent = (map: L.Map, eventName: string, ...args: any[]) => {
  const handlers = eventHandlersForEvent(map, eventName)

  handlers.forEach(handler => {
    handler.apply(null, args)
  })
}

export const removeAllEvents = (map: L.Map) => {
  eventHandlers.set(map, new Map())
}
