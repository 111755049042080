import React from "react"

import Timepicker from "components/Timepicker"

interface IReportTimeRangeProps {
  endTime: Date
  endTimeError?: string
  onEndTimeChange: (endTime: Date) => any
  onStartTimeChange: (startTime: Date) => any
  startTime: Date
  startTimeError?: string
}

const ReportTimeRange: React.FC<IReportTimeRangeProps> = ({ endTime, endTimeError, onEndTimeChange, onStartTimeChange, startTime, startTimeError }) => (
  <div className="flex flex-wrap md:flex-nowrap">
    <div className="w-full md:w-1/2 md:mr-1">
      <Timepicker error={startTimeError} label="Von" onChange={onStartTimeChange} value={startTime} />
    </div>
    <div className="w-full md:w-1/2 md:ml-1">
      <Timepicker error={endTimeError} label="Bis" onChange={onEndTimeChange} value={endTime} />
    </div>
  </div>
)

export default ReportTimeRange
