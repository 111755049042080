import L from "leaflet"

export const OSM_STREET_LAYER_NAME = "Open Street Maps"
export const GOOGLE_STREET_MONOCHROME_LAYER_NAME = "Google Street Monochrom"
export const GOOGLE_STREET_LAYER_NAME = "Google Street"
export const GOOGLE_SATELLITE_LAYER_NAME = "Google Satellite"
export const GOOGLE_HYBRID_LAYER_NAME = "Google Hybrid"

export const layerHasTileMargins = (layerName: string): boolean =>
  [GOOGLE_STREET_LAYER_NAME, GOOGLE_SATELLITE_LAYER_NAME, GOOGLE_HYBRID_LAYER_NAME].includes(layerName)

export const getLayers = () => {
  const osmStreetLayer = L.tileLayer(
    "https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png",
    { attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' }
  )
  const googleStreetMonochromeLayer = L.gridLayer.googleMutant({
    type: "roadmap",
    styles: [
      { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
      { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
      { featureType: "administrative.land_parcel", elementType: "labels.text.fill", stylers: [{ color: "#bdbdbd" }] },
      { featureType: "poi", elementType: "geometry", stylers: [{ color: "#eeeeee" }] },
      { featureType: "poi", elementType: "labels.text.fill", stylers: [{ color: "#757575" }] },
      { featureType: "poi.park", elementType: "geometry", stylers: [{ color: "#e5e5e5" }] },
      { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ color: "#9e9e9e" }] },
      { featureType: "road", elementType: "geometry", stylers: [{ color: "#ffffff" }] },
      { featureType: "road.arterial", elementType: "labels.text.fill", stylers: [{ color: "#757575" }] },
      { featureType: "road.highway", elementType: "geometry", stylers: [{ color: "#dadada" }] },
      { featureType: "road.highway", elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
      { featureType: "road.local", elementType: "labels.text.fill", stylers: [{ color: "#9e9e9e" }] },
      { featureType: "transit.line", elementType: "geometry", stylers: [{ color: "#e5e5e5" }] },
      { featureType: "transit.station", elementType: "geometry", stylers: [{ color: "#eeeeee" }] },
      { featureType: "water", elementType: "geometry", stylers: [{ color: "#c9c9c9" }] },
      { featureType: "water", elementType: "labels.text.fill", stylers: [{ color: "#9e9e9e" }] }
    ]
  })
  const googleStreetLayer = L.gridLayer.googleMutant({ type: "roadmap" })
  const googleSatelliteLayer = L.gridLayer.googleMutant({ type: "satellite" })
  const googleHybridLayer = L.gridLayer.googleMutant({ type: "hybrid" })

  return {
    [OSM_STREET_LAYER_NAME]: osmStreetLayer,
    [GOOGLE_STREET_MONOCHROME_LAYER_NAME]: googleStreetMonochromeLayer,
    [GOOGLE_STREET_LAYER_NAME]: googleStreetLayer,
    [GOOGLE_SATELLITE_LAYER_NAME]: googleSatelliteLayer,
    [GOOGLE_HYBRID_LAYER_NAME]: googleHybridLayer
  }
}
