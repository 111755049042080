import { AxiosRequestConfig } from "axios"

import { ISensor } from "../interfaces/ITelemetry"
import Resource from "../Resource"

class SensorResource extends Resource<ISensor> {
  get resourcePath() { return "/api/sensors" }
}

export default SensorResource
