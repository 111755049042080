import L from "leaflet"
import { cloneDeep, noop } from "lodash"
import area from "@turf/area"
import { polygon as wrapPolygon } from "@turf/helpers"

import { MAP_ENTITY_COLOR } from "./constants"

export type ProgressHandler = (attributes: PolygonAttributes) => any
export interface PolygonAttributes {
  area: number
  coordinates: number[][]
  isValid: boolean
  polygon: L.Polygon
}

const addPolygonEventHandlers = (polygon: L.Polygon, progressHandler: (...args: any[]) => void) => {
  polygon.on("editable:vertex:new", progressHandler)
  polygon.on("editable:vertex:deleted", progressHandler)
  polygon.on("editable:vertex:dragend", progressHandler)

  return () => {
    polygon.off("editable:vertex:new", progressHandler)
    polygon.off("editable:vertex:deleted", progressHandler)
    polygon.off("editable:vertex:dragend", progressHandler)
  }
}

const getLeafletChangeHandler = (polygon: L.Polygon, onProgress?: ProgressHandler) => {
  if (!onProgress) return noop

  return () => {
    const coordinates = (polygon.getLatLngs()[0] as L.LatLng[]).map(x => ([x.lng, x.lat]))
    const polygonValid = coordinates.length > 2

    // Make it a closed polygon so turf can properly calculate it's area.
    coordinates.push(coordinates[0])

    onProgress({
      area: polygonValid ? area(wrapPolygon([coordinates])) : 0,
      coordinates,
      isValid: polygonValid,
      polygon
    })
  }
}

export const drawPolygon = (map: L.Map, onProgress?: ProgressHandler) => {
  const polygon = map.editTools.startPolygon.call(map.editTools)
  const progressHandler = getLeafletChangeHandler(polygon, onProgress)
  const removeEventHandlers = addPolygonEventHandlers(polygon, progressHandler)

  polygon.setStyle({ color: MAP_ENTITY_COLOR })

  // Set the progress handler to it's initial state.
  progressHandler()

  return (reset: boolean = true) => {
    removeEventHandlers()
    polygon.disableEdit()

    if (reset) {
      polygon.removeFrom(map)
    }
  }
}

export const editPolygon = (polygon: L.Polygon, onProgress?: ProgressHandler) => {
  const progressHandler = getLeafletChangeHandler(polygon, onProgress)
  const initialCoordiantes = cloneDeep(polygon.getLatLngs())
  const removeEventHandlers = addPolygonEventHandlers(polygon, progressHandler)

  // Pass the initial progress of the existing polygon to the progress handler.
  progressHandler()

  polygon.enableEdit()

  return (reset: boolean = false) => {
    removeEventHandlers()
    polygon.disableEdit()

    if (reset) {
      polygon.setLatLngs(initialCoordiantes)
    }
  }
}
