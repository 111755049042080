import CookieCache from "./CookieCache"
import LocalStorageCache from "./LocalStorageCache"

const TEST_KEY = "__LOCALSTORAGE_TEST_KEY__"
const TEST_VALUE = "__LOCALSTORAGE_TEST_VALUE__"

function isLocalStorageAvailable() {
  try {
    if (
      typeof window?.localStorage?.setItem === "function" &&
      typeof window?.localStorage?.getItem === "function" &&
      typeof window?.localStorage?.removeItem === "function"
    ) {
      window.localStorage.setItem(TEST_KEY, TEST_VALUE)
      if (window.localStorage.getItem(TEST_KEY) === TEST_VALUE) {
        window.localStorage.removeItem(TEST_KEY)
        return true
      }
    }
  } catch { }

  return false
}

export const getCache = (cacheKey: string) => isLocalStorageAvailable()
  ? new LocalStorageCache(cacheKey)
  : new CookieCache(cacheKey)
