import { noop } from "lodash"

import { IDevice } from "api/interfaces/IDevice"
import { IPlace } from "api/interfaces/IPlace"
import { ITelemetry } from "api/interfaces/ITelemetry"
import { ITravelSheet } from "api/interfaces/ITravelSheet"
import { IUtilization } from "api/interfaces/IUtilization"
import { IZone } from "api/interfaces/IZone"

import IMap from "./IMap"
import { ProgressHandler as CircleProgressHandler } from "./circle"
import { ProgressHandler } from "./polygon"

class NullMap implements IMap {
  addTravelSheet(_: ITravelSheet) {}
  deleteTravelSheet(_: string) {}
  addDevices(_: IDevice[]): void {}
  addUtilization(_: IUtilization): void {}
  addPlaces(_: IPlace[]): void {}
  deleteAllPlaces(): void {}
  panToPlace(_: string): void {}
  drawCircle(_: CircleProgressHandler) {
    return noop
  }
  editPlace(_: string) {
    return noop
  }
  highlightPlace(_: string): void {}
  unhighlightPlace(_: string): void {}
  deleteUtilization(_: string): void {}
  panToUtilization(_: string): void {}
  deleteDevices(_: string[]): void {}
  fitDevices(): void {}
  addZones(_: IZone[]): void {}
  deleteAllZones(): void {}
  highlightDevice(_: string): void {}
  unhighlightDevice(_: string): void {}
  hideDevices(_: string[]): void {}
  showDevices(_: string[]): void {}
  highlightZone(_: string): void {}
  unhighlightZone(_: string): void {}
  setTelemetry(_: ITelemetry[]): void {}
  panToDevice(_: string): void {}
  panToZone(_: string): void {}
  drawPolygon(_: ProgressHandler) {
    return noop
  }
  drawPolyline(_: ProgressHandler) {
    return noop
  }
  editZone(_: string) {
    return noop
  }
  on(_: string, _1: Function) {
    return noop
  }
  trigger(_: string, ..._1: any[]) {}
  removeAllEvents(): void {}
}

export default NullMap
