import { useEffect, useRef, useState } from "react"
import { debounce } from "lodash"

const DEFAULT_DEBOUNCE = 300
const DEFAULT_OPTIONS = { useSyncState: true }

const useDebouncedState = <T>(
  initialState: T,
  debounceTime: number = DEFAULT_DEBOUNCE,
  options: Partial<typeof DEFAULT_OPTIONS> = {}
): [T, T, (value: T) => void] => {
  const [state, setState] = useState<T>(initialState)
  const [debouncedState, setDebouncedState] = useState<T>(initialState)
  const debounceFunction = useRef<(value: T) => void>()

  options = { ...DEFAULT_OPTIONS, ...options }

  useEffect(() => {
    debounceFunction.current = debounce((value: T) => {
      setDebouncedState(value)
    }, debounceTime)
  }, [debounceTime])

  const setStates = (value: T) => {
    options.useSyncState && setState(value)
    debounceFunction.current(value)
  }

  return [state, debouncedState, setStates]
}

export default useDebouncedState
