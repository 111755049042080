import { useEffect, useRef, useState } from "react"

const useForceRerender = (time: number) => {
  const timeout = useRef<NodeJS.Timeout>()
  const [_, setState] = useState(0)

  const forceRerender = () => {
    setState(state => state + 1)
  }

  const scheduleForceRerender = () => {
    timeout.current = setTimeout(() => {
      if (!timeout.current) return

      forceRerender()
      scheduleForceRerender()
    }, time)
  }

  useEffect(() => {
    scheduleForceRerender()

    return () => {
      clearTimeout(timeout.current)
      timeout.current = undefined
    }
  }, [time])
}

export default useForceRerender
