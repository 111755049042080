import { useEffect } from "react"

const useClassToggle = (element: HTMLElement, className: string) => {
  useEffect(() => {
    element.classList.add(className)

    return () => {
      element.classList.remove(className)
    }
  })
}

export default useClassToggle
