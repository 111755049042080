import React from "react"
import Flatpickr from "react-flatpickr"
import { German } from "flatpickr/dist/l10n/de"

interface ITimepickerProps {
  error?: string
  label?: string
  onChange: (value: Date) => any
  value: Date
}

const FLATPICKER_OPTIONS = {
  dateFormat: "Y-m-d H:i",
  enableTime: true,
  locale: German,
  time_24hr: true
}

const Timepicker: React.FunctionComponent<ITimepickerProps> = ({ error, label, onChange, value }) => {
  return (
    <>
      <div className="mt-2 w-full flex">
        <div className={`p-2 w-16 flex-shrink-0 bg-gray-50 border rounded-l-md flex justify-center items-center${error ? " border-red-600" : ""}`}>
          {label}
        </div>
        <Flatpickr
          onChange={date => {
            // Sometimes flatpickr will send an array with the first element being the actual date
            // and I have not taken the time to figure out why yet.
            Array.isArray(date) ? onChange(date[0]) : onChange(date)
          }}
          options={FLATPICKER_OPTIONS}
          render={({ value, ...props }, ref) => (
            <input
              className={`p-2 w-full border border-l-0 rounded-r-md cursor-pointer${error ? " border-red-600" : ""}`}
              ref={ref}
              {...props}

            />
          )}
          value={value}
        />
      </div>
      {error && (
        <div className="text-red-600 text-sm">{error}</div>
      )}
    </>
  )
}

export default Timepicker
