import { Controller } from "stimulus"

class EmployeeInsightsController extends Controller {
  private submitButtonTarget: HTMLInputElement
  private smsNoticeTarget: HTMLInputElement

  static targets = ["submitButton", "smsNotice"]

  handleActionChange(e: InputEvent) {
    const value = (e.target as HTMLInputElement).value

    if (value) {
      this.submitButtonTarget.disabled = false
    }

    if (value === "grant_and_notify") {
      this.smsNoticeTarget.classList.remove("hidden")
    } else {
      this.smsNoticeTarget.classList.add("hidden")
    }
  }
}

export default EmployeeInsightsController
