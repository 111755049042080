import React from "react"
import PropTypes from "prop-types"

import { formatDateTime, formatDuration } from "utils/date"
import { formatMeters, formatSpeed } from "utils/distance"

const DrivePopup = ({ arrival, averageSpeed, departure, duration, length, maxSpeed }) => (
  <table className="popup-table">
    <tbody>
      <tr>
        <td>Abfahrt</td>
        <td>{formatDateTime(departure)}</td>
      </tr>
      <tr>
        <td>Ankunft</td>
        <td>{formatDateTime(arrival)}</td>
      </tr>
      <tr>
        <td>Dauer</td>
        <td>{formatDuration(duration)}</td>
      </tr>
      <tr>
        <td>Länge</td>
        <td>{formatMeters(length)}</td>
      </tr>
      <tr>
        <td>Geschwindigkeit max.</td>
        <td>{maxSpeed == null ? "K. A." : formatSpeed(maxSpeed)}</td>
      </tr>
      <tr>
        <td>Geschwindgkeit Ø</td>
        <td>{averageSpeed == null ? "K. A." : formatSpeed(averageSpeed)}</td>
      </tr>
    </tbody>
  </table>
)

DrivePopup.propTypes = {
  arrival: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  averageSpeed: PropTypes.number,
  departure: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  duration: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  maxSpeed: PropTypes.number
}

export default DrivePopup
