import L from "leaflet"

import { IZone } from "api/interfaces/IZone"

import { MAP_ENTITY_COLOR, MAP_ENTITY_COLOR_HOVER } from "../../constants"
import { flipPolygonCoordinatesLatLng } from "../../helpers"
import { getZoneEventName, triggerMapEvent } from "../../mapEvents"
import { editPolygon, ProgressHandler } from "../../polygon"

class ZonePolygon {
  public zone: IZone
  private polygon: L.Polygon

  constructor(layer: L.FeatureGroup, zone: IZone) {
    this.zone = zone
    this.polygon = L.polygon(flipPolygonCoordinatesLatLng(zone.coordinates), { color: MAP_ENTITY_COLOR })
      .addTo(layer)
      .bindTooltip(zone.name, { sticky: true })
      .on("mouseover", () => this.highlight())
      .on("mouseout", () => this.unhighlight())
      .on("click", () => {
        triggerMapEvent(layer.map, getZoneEventName("click"), zone)
        triggerMapEvent(layer.map, getZoneEventName("click", zone.id), zone)
      })
  }

  get bounds() {
    return this.polygon.getBounds()
  }

  redraw() {
    this.polygon.redraw()
  }

  highlight() {
    this.polygon.setStyle({ color: MAP_ENTITY_COLOR_HOVER })
  }

  unhighlight() {
    this.polygon.setStyle({ color: MAP_ENTITY_COLOR })
  }

  edit(onProgress?: ProgressHandler) {
    return editPolygon(this.polygon, onProgress)
  }
}

export default ZonePolygon
