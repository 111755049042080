import { AxiosRequestConfig } from "axios"

import { IRawUtilization, IUtilization, IZoneUtilization } from "api/interfaces/IUtilization"

import { IUnpersistedZone, IZone } from "../interfaces/IZone"
import Resource from "../Resource"
import { transformUtilizationDetails } from "api/helpers"

interface Icreate {
  data: IUnpersistedZone
  options?: AxiosRequestConfig
}

interface Iutilization {
  data: IZoneUtilization
  options?: AxiosRequestConfig
}

class ZoneResource extends Resource<IZone> {
  get resourcePath() { return "/api/zones" }

  create = ({ data, options }: Icreate): Promise<IZone> =>
    this.post<IUnpersistedZone, IZone>(this.resourcePath, data, options)

  utilization = async ({ data: { endTime, startTime, zoneId }, options }: Iutilization): Promise<IUtilization> => {
    const response = await this.post<Object, IRawUtilization>(
      `${this.resourcePath}/${zoneId}/utilization`, { startTime, endTime }, options
    )

    return {
      ...response,
      coordinates: response.coordinates.map(([lng, lat]) => ({ lat, lng })),
      endTime,
      startTime,
      utilizations: transformUtilizationDetails(response.utilizations),
      zoneId
    } as IUtilization
  }
}

export default ZoneResource
