import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import ResourceError from "./ResourceError"
import ResourceResponse from "./ResourceResponse"

const client = axios.create({
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json"
  }
})

abstract class Resource<T> {
  abstract get resourcePath(): string

  protected client: AxiosInstance = client

  get = async <U = T>(url: string, config?: AxiosRequestConfig) =>
    this.dispatchRequest<U>(() => this.client.get(url, config))

  post = async <U = T, S = T>(url: string, data: U, config?: AxiosRequestConfig) =>
    this.dispatchRequest<S>(() => this.client.post(url, data, config))

  patch = async <U = T, S = T>(url: string, data: U, config?: AxiosRequestConfig) =>
    this.dispatchRequest<S>(() => this.client.patch(url, data, config))

  delete = async <U = T>(url: string, config?: AxiosRequestConfig) =>
    this.dispatchRequest<U>(() => this.client.delete(url, config))

  all = (params?: { options?: AxiosRequestConfig }) => this.get<T[]>(this.resourcePath, params?.options)

  find = ({ id, options }: { id: string, options?: AxiosRequestConfig }) => this.get(`${this.resourcePath}/${id}`, options)

  update = ({ id, data, options }: { id: string, data?: any, options?: AxiosRequestConfig }) => this.patch(`${this.resourcePath}/${id}`, data, options)

  destroy = ({ id, options }: { id: string, options?: AxiosRequestConfig }) => this.delete(`${this.resourcePath}/${id}`, options)

  private async dispatchRequest <U>(request: () => Promise<AxiosResponse<U>>): Promise<U> {
    try {
      const response = await request()
      return this.buildResponse<U>(response).data
    } catch (e) {
      throw this.buildError(e)
    }
  }

  private buildResponse <U>(axiosResponse: AxiosResponse<U>) {
    return ResourceResponse.buildFromAxiosResponse<U>(axiosResponse)
  }

  private buildError(e: AxiosError) {
    return ResourceError.buildFromAxiosError(e)
  }
}

export default Resource
