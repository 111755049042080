import { AxiosRequestConfig } from "axios"

import { IEventTrigger, IUnpersistedEventTrigger } from "api/interfaces/IEvent"

import Resource from "../Resource"
import { isPresent } from "utils"

interface Icreate {
  data: IUnpersistedEventTrigger
  options?: AxiosRequestConfig
}

class EventTriggerResource extends Resource<IEventTrigger> {
  get resourcePath() { return "/api/event_triggers" }

  create = ({ data, options }: Icreate): Promise<IEventTrigger> =>
    this.post<IUnpersistedEventTrigger, IEventTrigger>(this.resourcePath, {
      ...data,
      mobileTelephone: isPresent(data.mobileTelephone) ? data.mobileTelephone : null
    }, options)

  update = ({ id, data, options }): Promise<IEventTrigger> =>
    this.patch<IUnpersistedEventTrigger, IEventTrigger>(`${this.resourcePath}/${id}`, {
      ...data,
      mobileTelephone: isPresent(data.mobileTelephone) ? data.mobileTelephone : null
    }, options)
}

export default EventTriggerResource
