import L from "leaflet"

import { ISection, ITravelSheet } from "api/interfaces/ITravelSheet"
import { getStopClusterMarker } from "tracking/map/icons"
import { TObject } from "utils"

import Drive from "./Drive"
import Stop from "./Stop"
import { IDrawer } from "./types"
import { getTravelSheetEventName, onMapEvent } from "tracking/map/mapEvents"

class TravelSheet {
  private travelSheet: ITravelSheet
  private parentLayer: L.FeatureGroup
  private layer: L.FeatureGroup
  private driveLayer: L.FeatureGroup
  private stopLayer: L.MarkerClusterGroup
  private drawers: TObject<IDrawer> = {}
  private unsubscribeFromEvents: Function

  constructor(parentLayer: L.FeatureGroup, travelSheet: ITravelSheet) {
    this.parentLayer = parentLayer
    this.layer = L.featureGroup().addTo(this.parentLayer)
    this.driveLayer = L.featureGroup().addTo(this.layer)
    this.stopLayer = L.markerClusterGroup({ iconCreateFunction: getStopClusterMarker, disableClusteringAtZoom: 18 }).addTo(this.layer)
    this.travelSheet = travelSheet
    this.draw()
    this.unsubscribeFromEvents = this.subscribeToEvents()
  }

  delete() {
    this.parentLayer.removeLayer(this.layer)

    for (const drawer of Object.values(this.drawers)) {
      drawer.delete()
    }

    this.unsubscribeFromEvents()
  }

  fit() {
    const bounds = this.layer.getBounds()

    if (bounds.isValid()) {
      this.layer.map.fitBounds(bounds)
    }

    this.layer.bringToFront()
  }

  private subscribeToEvents() {
    return onMapEvent(this.parentLayer.map, getTravelSheetEventName("open", this.travelSheet.id), () => {
      this.fit()
    })
  }

  private draw() {
    for (const day of this.travelSheet.days) {
      for (const section of day.sections) {
        this.drawSection(section)
      }
    }
  }

  private drawSection(section: ISection) {
    if (section.type === "drive") this.drawers[section.id] = new Drive(this.driveLayer, section, this.travelSheet.color)
    else if (section.type === "stop") this.drawers[section.id] = new Stop(this.stopLayer, section)
  }
}

export default TravelSheet
