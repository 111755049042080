import L from "leaflet"

import { IZone } from "api/interfaces/IZone"
import {  ProgressHandler } from "tracking/map/polygon"
import { TObject } from "utils"

import ZonePolygon from "./ZonePolygon"

class ZoneLayer {
  private layer: L.FeatureGroup
  private map: L.Map
  private zonePolygons: TObject<ZonePolygon> = {}

  constructor(map: L.Map) {
    this.map = map
    this.layer = L.featureGroup().addTo(map)
  }

  addZones = (zones: IZone[]) => {
    zones.forEach(zone => {
      const { id } = zone

      if (!this.zonePolygons[id]) {
        this.zonePolygons[id] = new ZonePolygon(this.layer, zone)
      }
    })
  }

  deleteAllZones = () => {
    this.layer.removeFrom(this.map)
    this.layer = L.featureGroup().addTo(this.map)
    this.zonePolygons = {}
  }

  highlightZone(zoneId: string) {
    this.zonePolygons[zoneId]?.highlight()
  }

  unhighlightZone(zoneId: string) {
    this.zonePolygons[zoneId]?.unhighlight()
  }

  panToZone (zoneId: string) {
    this.map.fitBounds(this.zonePolygons[zoneId].bounds)

    // Sometimes, a weird bug appears where the zones do not get properly drawn
    // after the map panned to a certain zone.
    for (const zonePolygon of Object.values(this.zonePolygons)) {
      zonePolygon.redraw()
    }
  }

  editZone (zoneId: string, onProgress?: ProgressHandler) {
    return this.zonePolygons[zoneId].edit(onProgress)
  }
}

export default ZoneLayer
