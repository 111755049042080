import React, { useMemo, useState } from "react"
import { isEmpty, map } from "lodash"

import { ReportResource } from "api"
import FlashMessages from "FlashMessages"
import useReportFormState, { IReportFormState, IReportFormStateErrors } from "hooks/useReportFormState"

import ReportForm from "./ReportForm"

const reportFormValidator = (state: IReportFormState) => {
  const errors = {} as IReportFormStateErrors

  if (isEmpty(state.devices)) errors.devices = "Es muss mindestens ein Gerät ausgewählt werden."
  if (state.endTime <= state.startTime) errors.endTime = errors.startTime = "Ungültiger Zeitraum."
  if ((state.reoccurWeekly || state.reoccurDaily || state.reoccurMonthly)  && !state.name.trim()) errors.name = "Der Name für wiederkehrende Berichte darf nicht leer sein."

  return errors
}

const ReportFormContainer: React.FC = () => {
  const {
    deviceGroups,
    errors,
    isLoading,
    isValid,
    selectableDevices,
    selectableFormats,
    setState,
    state
  } = useReportFormState(reportFormValidator)
  const [isJustSubmitted, setIsJustSubmitted] = useState(false)
  const submittable = isValid && !isJustSubmitted

  const handleSubmit = async () => {
    const { devices, ...report } = state
    try {
      await ReportResource.create({ ...report, deviceIds: map(devices, "id") })
      setIsJustSubmitted(true)
      FlashMessages.addMessage("notice", "Der Bericht wird erstellt. Sie finden ihn in Kürze in Ihrer Berichts-Liste.")
    } catch (e) {
      FlashMessages.addMessage("alert", "Der Bericht konnte nicht erstellt werden. Prüfen Sie bitte Ihre Internetverbindung.")
      throw e
    }
  }

  const handleStateChange = (stateSlice: Partial<IReportFormState>) => {
    setState(stateSlice)
    setIsJustSubmitted(false)
  }

  return (
    <ReportForm
      deviceGroups={deviceGroups}
      devices={selectableDevices}
      errors={errors}
      formats={selectableFormats}
      isLoading={isLoading}
      onStateChange={handleStateChange}
      onSubmit={handleSubmit}
      showTimeRange
      state={state}
      submitLabel="Generieren"
      submittable={submittable}
    />
  )
}

export default ReportFormContainer
