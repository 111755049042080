import React from "react"
import ReactDOM from "react-dom"
import L from "leaflet"

import { ISection } from "api/interfaces/ITravelSheet"
import { StopPopup } from "components/layers"
import { MAP_ENTITY_COLOR_DISABLED } from "tracking/map/constants"
import { stopMarker } from "tracking/map/icons"

import { IDrawer } from "./types"
import { getAddress } from "tracking/map/helpers"
import { getSectionEventName, onMapEvent } from "tracking/map/mapEvents"

class Stop implements IDrawer {
  private parentLayer: L.MarkerClusterGroup
  private section: ISection
  private polyline: L.Polyline
  private marker: L.Marker
  private unsubscribeFromEvents: Function

  constructor(parentLayer: L.MarkerClusterGroup, section: ISection) {
    this.parentLayer = parentLayer
    this.section = section
    this.polyline = L.polyline(section.points, { color: MAP_ENTITY_COLOR_DISABLED }).addTo(parentLayer)
    this.marker = L.marker(section.points[0], { icon: stopMarker })
      .bindPopup(this.getPopup())
      .addTo(parentLayer)
    this.unsubscribeFromEvents = this.subscribeToEvents()
  }

  bringToCenter() {
    if (this.marker.isPopupOpen()) {
      this.parentLayer.map.panTo(this.marker.getLatLng())
      return
    }

    if (typeof this.parentLayer.zoomToShowLayer === "function") {
      this.parentLayer.zoomToShowLayer(this.marker, () => {
        this.marker.openPopup()
      })
    } else {
      this.marker.openPopup()
    }
  }

  delete() {
    this.unsubscribeFromEvents()
  }

  private subscribeToEvents() {
    return onMapEvent(this.parentLayer.map, getSectionEventName("click", this.section.id), () => {
      this.bringToCenter()
    })
  }

  private getPopup = () => {
    const popup = L.popup()

    popup.setContent(() => {
      const element = document.createElement("div")

      ReactDOM.render(
        <StopPopup
          arrival={this.section.startTime}
          departure={this.section.endTime}
          duration={this.section.durationInSeconds}
          addressPromise={getAddress(this.section.points[0].lat, this.section.points[0].lng)}
        />, element)

      return element
    })

    return popup
  }
}

export default Stop
