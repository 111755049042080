import { ICashboxWarehouse } from "../interfaces/ICashbox"
import Resource from "../Resource"

interface IproductBalances {
  cashboxWarehouseId: string
}

class CashboxWarehouseResource extends Resource<ICashboxWarehouse> {
  get resourcePath() { return "/api/cashboxes/warehouses" }

  productBalances = ({ cashboxWarehouseId }: IproductBalances) =>
    this.get(`${this.resourcePath}/product_balances`, { params: { cashboxWarehouseId, pageSize: 1000 } })
}

export default CashboxWarehouseResource
