import { DeviceResource } from "api"
import { ITelemetry } from "api/interfaces/ITelemetry"

import ICurrentTelemetryEventHandler from "./ICurrentTelemetryEventHandler"

export const FETCH_INTERVAL = 8000

class CurrentTelemetryFetcher {
  public initialFetchPromise: Promise<void>

  private _deviceIds: string[] = []
  private mostRecentUpdateTimestamp?: Date
  private stopped: boolean = true
  private timeout: NodeJS.Timeout
  private eventHandler: ICurrentTelemetryEventHandler

  constructor(eventHandler: ICurrentTelemetryEventHandler) {
    this.eventHandler = eventHandler
  }

  set deviceIds(value: string[]) {
    this._deviceIds = value
    this.mostRecentUpdateTimestamp = undefined
  }

  start = () => {
    clearTimeout(this.timeout)
    this.stopped = false
    this.initialFetchPromise = this.fetchCurrentTelemetry()
  }

  stop = () => {
    clearTimeout(this.timeout)
    this.stopped = true
  }

  private fetchCurrentTelemetry = async () => {
    if (this.stopped) return

    let telemetry: ITelemetry[] = []
    const telemetryPromise = DeviceResource.currentTelemetry({
      ...(this._deviceIds && { deviceIds: this._deviceIds }),
      ...(this.mostRecentUpdateTimestamp && { updatedAtGt: this.mostRecentUpdateTimestamp.toISOString() })
    })

    const tempMostRecentUpdateTimestamp = new Date()

    try {
      telemetry = await telemetryPromise
      this.mostRecentUpdateTimestamp = tempMostRecentUpdateTimestamp

      if (!this.stopped) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.fetchCurrentTelemetry, FETCH_INTERVAL)
      }
    } catch (e) {
      this.stop()

      if (this.eventHandler.onError) {
        this.eventHandler.onError(e, this)
      }

      throw e
    }

    if (telemetry.length > 0) {
      this.eventHandler.onTelemetryChange(telemetry)
    }
  }
}

export default CurrentTelemetryFetcher
