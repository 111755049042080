export function marshalToJson (data: object): string {
  return JSON.stringify(data)
}

export function unmarshalFromJson (json: string | null): Record<string, any> {
  if (json == null) return {}

  try {
    return JSON.parse(json)
  } catch {
    return {}
  }
}
