import { useEffect, useRef, useState } from "react"
import { throttle } from "lodash"

const useHighlight = () => {
  const [isHighlighted, setIsHighlighted] = useState(false)
  const timeout = useRef<NodeJS.Timeout>()

  const triggerHighlight = throttle(() => {
    setIsHighlighted(true)

    timeout.current = setTimeout(() => {
      triggerHighlight.cancel()
      setIsHighlighted(false)
    }, 2980)
  }, 3000)

  useEffect(() => {
    clearTimeout(timeout.current)
  }, [])

  return {
    isHighlighted,
    triggerHighlight
  }
}

export default useHighlight
