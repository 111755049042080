import Resource from "../Resource"

interface IAddLineItem {
  cartId: string
  productId: string
  quantity: string
}

interface IDestroyLineItem {
  cartId: string
  lineItemId: string
}

class CartResource extends Resource<Object> {
  get resourcePath() { return "/api/carts" }

  addLineItem = ({ cartId, productId, quantity }: IAddLineItem) =>
    this.post(`${this.resourcePath}/${cartId}/line_items`, { quantity, productId })

  destroyLineItem = ({ cartId, lineItemId }: IDestroyLineItem) =>
    this.delete(`${this.resourcePath}/${cartId}/line_items/${lineItemId}`)
}

export default CartResource
