import Cart from "./resources/CartResource"
import CashboxWarehouse from "./resources/CashboxWarehouseResource"
import Device from "./resources/DeviceResource"
import DeviceGroup from "./resources/DeviceGroupResource"
import EventTrigger from "./resources/EventTriggerResource"
import Google from "./resources/GoogleResource"
import Location from "./resources/LocationResource"
import Place from "./resources/PlaceResource"
import ReoccurringReport from "./resources/ReoccurringReportResource"
import Report from "./resources/ReportResource"
import Sensor from "./resources/SensorResource"
import Shop from "./resources/ShopResource"
import User from "./resources/UserResource"
import Zone from "./resources/ZoneResource"

// export { IDeviceObject } from "./interfaces/IDeviceObject"
// export { ITelemetry } from "./interfaces/ITelemetry"
// export { IRawPoint, IRawSection, IRawTrip, IPoint, ISection, ITrip } from "./interfaces/ITrip"
// export { IPositions, IZone } from "./interfaces/IZone"


export const CashboxWarehouseResource = new CashboxWarehouse()
export const CartResource = new Cart()
export const DeviceResource = new Device()
export const DeviceGroupResource = new DeviceGroup()
export const EventTriggerResource = new EventTrigger()
export const GoogleResource = new Google()
export const LocationResource = new Location()
export const PlaceResource = new Place()
export const ReoccurringReportResource = new ReoccurringReport()
export const ReportResource = new Report()
export const SensorResource = new Sensor()
export const ShopResource = new Shop()
export const ZoneResource = new Zone()
export const UserResource = new User()
