import React from "react"

import Autocomplete, { IOption } from "components/Autocomplete"

interface IReportKindProps {
  kind: string
  onChange: (kind: string) => any
}

const OPTIONS = [
  { value: "machine_time_in_zones", label: "Maschinenzeit nach Zone" },
  { value: "travel_sheet_short", label: "Reiseblatt kurz" },
  { value: "travel_sheet_detailled", label: "Reiseblatt detailliert" }
]

const ReportKind: React.FC<IReportKindProps> = ({ kind, onChange }) => (
  <>
    <h2 className="h2 mb-2 pb-0">Berichtsform</h2>
    <Autocomplete
      defaultValue={kind}
      onChange={option => onChange((option as IOption).value)}
      id="report-kind-input"
      options={OPTIONS}
      value={kind}
    />
  </>
)

export default ReportKind
