import { ITravelSheet } from "api/interfaces/ITravelSheet"
import { IUtilization } from "api/interfaces/IUtilization"
import { ISerializedTimePeriod } from "components/TimePeriodSelector"

export interface TrackingPageState {
  activeTabLeft: LeftSidebarTab
  collapsedDeviceGroupIds: string[]
  deviceFilter: IDeviceFilter
  filteredDeviceIds: string[]
  isTravelSheetSidebarVisible: boolean
  openModal: string | null
  placeFilter: IPlaceFilter
  selectedUtilization: string | null
  selectedDeviceIds: Record<string, boolean> | null
  selection: ISelection
  timePeriod: ISerializedTimePeriod
  zoneFilter: IZoneFilter
}

export enum LeftSidebarTab {
  DEVICES = "DEVICES",
  PLACES = "PLACES",
  SENSORS = "SENSORS",
  ZONES = "ZONES"
}

export interface IPlaceFilter {
  name: string
}

export interface IZoneFilter {
  name: string
}

export interface IDeviceFilter {
  name: string
  orderBy: string
  zoneIds: string[]
  zoneThreshold: string
}

export interface ISelection {
  type: "device" | "place" | "zone" | null
  id: string | null
}

export enum UtilizationOptionValueType {
  DEVICE_UTILIZATION = "DEVICE_UTILIZATION",
  TRAVEL_SHEET = "TRAVEL_SHEET",
  ZONE_UTILIZATION = "ZONE_UTILIZATION"
}

export interface Utilization {
  type: UtilizationOptionValueType,
  value: ITravelSheet | IUtilization[]
}
