const diffArrays = <T>(leftArray: T[], rightArray: T[]): {
  addedItems: T[],
  deletedItems: T[]
} => {
  const addedItems = [] as T[]
  const deletedItems = [] as T[]
  const leftSet = new Set(leftArray)
  const rightSet = new Set(rightArray)

  for (const item of rightSet) {
    if (!leftSet.has(item)) {
      addedItems.push(item)
    } else {
      leftSet.delete(item)
    }
  }

  for (const item of leftSet) {
    if (!rightSet.has(item)) {
      deletedItems.push(item)
    }
  }

  return { addedItems, deletedItems }
}

export default diffArrays
