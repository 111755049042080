import { AxiosResponse } from "axios";

class ResourceResponse<T> {
  public data: T
  public status: number

  static buildFromAxiosResponse<U>(axiosResponse: AxiosResponse<U>) {
    const response = new ResourceResponse<U>(axiosResponse.data)
    response.status = axiosResponse.status
    return response
  }

  constructor(data: T) {
    this.data = data
  }
}

export default ResourceResponse
