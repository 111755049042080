import { format } from "date-fns"

const DATE_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
  timeZone: "Europe/Amsterdam"
}

export const formatDateTime = (date: Date | string) => {
  if (typeof date === "string") date = new Date(date)
  return Intl.DateTimeFormat("de-DE", DATE_TIME_OPTIONS).format(date).replace(",", " ")
}

export const formatDate = (date: Date | string) => {
  if (typeof date === "string") date = new Date(date)
  return format(date, "dd.LL.uuuu")
}

export const formatTime = (date: Date | string) => {
  if (typeof date === "string") date = new Date(date)
  return format(date, "HH:mm")
}

export const formatParameterDate = (date: Date | string) => {
  if (typeof date === "string") date = new Date(date)
  return format(date, "yyyy-MM-dd")
}

export const formatDuration = (durationInSeconds: number) => {
  const totalMinutes = Math.floor(durationInSeconds / 60)
  const hours = Math.floor(totalMinutes / 60)
  const minutes = (totalMinutes - hours * 60)

  const result = [`${minutes} Min.`]
  if (hours > 0) result.unshift(`${hours} Std.`)

  return result.join(" ")
}
