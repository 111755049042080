import { IPositions } from 'api/interfaces/IZone'
import { LocationResource } from "../../api"

export const getTrackableDescription = trackable => trackable.name || "Unbekannt"

export const getAddress = async (lat: number, lng: number) => {
  const response = await LocationResource.reverseGeocode(lat, lng)
  return response.full
}

export const flipPolygonCoordinatesLatLng = (coordinates: number[][]): IPositions =>
  coordinates.map(([lng, lat, ...rest]) => [lat, lng, ...rest]) as IPositions
