import { AxiosRequestConfig } from "axios"
import { Action } from "redux"

export enum ResourceType {
  CASHBOX_WAREHOUSE = "CashboxWarehouse",
  DEVICE = "Device",
  DEVICE_GROUP = "DeviceGroup",
  EVENT_TRIGGER = "EventTrigger",
  PLACE = "Place",
  SENSOR = "Sensor",
  USER = "User",
  ZONE = "Zone"
}

export enum ApiActionType {
  ERROR = "api/requestError",
  START = "api/requestStart",
  SUCCESS = "api/requestSuccess"
}

export interface ApiActionMeta<T = any> {
  args?: T
  replaceResources?: boolean
  options?: AxiosRequestConfig
}

export interface ApiAction extends Action {
  meta: ApiActionMeta
  resourceKey: ResourceKey
  resourceType: ResourceType
  resourceAction: string
  responsePromise?: Promise<any>
  payload?: any
  type: ApiActionType
}

export interface ApiPayloadAction extends ApiAction {
  payload: any
}

export interface PayloadAction<T> extends Action {
  payload: T
}

export enum ResourceKey {
  CASHBOX_PRODUCT_BALANCES = "cashboxProductBalances",
  CASHBOX_WAREHOUSES = "cashboxWarehouses",
  DEVICES = "devices",
  DEVICE_GROUPS = "deviceGroups",
  DEVICE_TELEMETRY = "deviceTelemetry",
  EVENT_TRIGGERS = "eventTriggers",
  PLACES = "places",
  SENSORS = "sensors",
  TRAVEL_SHEET = "travelSheets",
  USERS = "users",
  UTILIZATIONS = "utilizations",
  ZONES = "zones"
}
