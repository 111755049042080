import L from "leaflet"

import { IPlace } from "api/interfaces/IPlace"
import { TObject } from "utils"

import PlacePolygon from "./PlacePolygon"
import { ProgressHandler } from "tracking/map/circle"

class ZoneLayer {
  private layer: L.FeatureGroup
  private map: L.Map
  private placePolygons: TObject<PlacePolygon> = {}

  constructor(map: L.Map) {
    this.map = map
    this.layer = L.featureGroup().addTo(map)
  }

  addPlaces = (places: IPlace[]) => {
    places.forEach(place => {
      const { id } = place

      if (!this.placePolygons[id]) {
        this.placePolygons[id] = new PlacePolygon(this.layer, place)
      }
    })
  }

  deleteAllPlaces = () => {
    this.layer.removeFrom(this.map)
    this.layer = L.featureGroup().addTo(this.map)
    this.placePolygons = {}
  }

  highlightPlace(placeId: string) {
    this.placePolygons[placeId]?.highlight()
  }

  unhighlightPlace(placeId: string) {
    this.placePolygons[placeId]?.unhighlight()
  }

  panToPlace (placeId: string) {
    this.map.fitBounds(this.placePolygons[placeId].bounds)

    // Sometimes, a weird bug appears where the places do not get properly drawn
    // after the map panned to a certain place.
    for (const placePolygon of Object.values(this.placePolygons)) {
      placePolygon.redraw()
    }
  }

  editPlace (placeId: string, progressHandler?: ProgressHandler) {
    return this.placePolygons[placeId].edit(progressHandler)
  }
}

export default ZoneLayer
