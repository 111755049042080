import { IDevice } from "api/interfaces/IDevice"
import { includes, uniq } from "lodash"

export enum DEVICE_KINDS {
  EHI = "ehi",
  KFZ = "kfz",
  MDE = "mde",
  MZE = "mze",
  PZE = "pze",
  SEN = "sen"
}

const KIND_TRANSLATIONS = Object.freeze({
  [DEVICE_KINDS.EHI]: "Erntehilfe",
  [DEVICE_KINDS.KFZ]: "KFz",
  [DEVICE_KINDS.MDE]: "MDE",
  [DEVICE_KINDS.MZE]: "Maschinenzeit",
  [DEVICE_KINDS.PZE]: "Personalzeit",
  [DEVICE_KINDS.SEN]: "Sensor"
})

const TRAVEL_SHEET_KINDS = Object.freeze(["kfz", "mze", "pze"])
const HARVEST_SHEET_KINDS = Object.freeze(["ehi"])
const DEVICE_UTILIZATION_KINDS = Object.freeze(["mze"])
const UTILIZATION_KINDS = uniq([...TRAVEL_SHEET_KINDS, ...HARVEST_SHEET_KINDS, ...DEVICE_UTILIZATION_KINDS])

export const getHumanDeviceKind = ({ kind }: IDevice) => KIND_TRANSLATIONS[kind]
export const supportsUtilization = ({ kind }: IDevice) => includes(UTILIZATION_KINDS, kind)
export const supportsTravelSheet = ({ kind }: IDevice) => includes(TRAVEL_SHEET_KINDS, kind)
export const supportsHarvestSheet = ({ kind }: IDevice) => includes(HARVEST_SHEET_KINDS, kind)
export const supportsDeviceUtilization = ({ kind }: IDevice) => includes(DEVICE_UTILIZATION_KINDS, kind)
