import L from "leaflet"

import { ITravelSheet } from "api/interfaces/ITravelSheet"
import { TObject } from "utils"

import TravelSheet from "./TravelSheet"

class TravelSheetLayer {
  private layer: L.FeatureGroup
  private travelSheets: TObject<TravelSheet> = {}

  constructor(map: L.Map) {
    this.layer = L.featureGroup().addTo(map)
  }

  add(travelSheet: ITravelSheet) {
    this.travelSheets[travelSheet.id] = new TravelSheet(this.layer, travelSheet)
  }

  delete(travelSheetId: string) {
    const travelSheet = this.travelSheets[travelSheetId]

    if (travelSheet) {
      travelSheet.delete()
      delete this.travelSheets[travelSheetId]
    }
  }
}

export default TravelSheetLayer
