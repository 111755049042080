import { useContext, useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { noop } from "lodash"

import { CircleAttributes } from "tracking/map/circle"
import MapContext from "tracking/map/MapContext"
import { PolygonAttributes } from "tracking/map/polygon"
import { TState } from "tracking/store/store"
import { setIsEditing } from "tracking/store/actionCreators"
import { PolylineAttributes } from "tracking/map/polyline"

const INITIAL_POLYLINE_STATE: PolylineAttributes = {
  coordinates: [],
}

const INITIAL_POLYGON_STATE: PolygonAttributes = {
  area: 0,
  coordinates: [],
  isValid: false,
  polygon: null
}

const INITIAL_CIRCLE_STATE: CircleAttributes = {
  area: 0,
  circle: null,
  coordinates: { lat: 0, lng: 0 },
  isValid: false,
  radius: 0
}

const mapSelector = (state: TState) => state.map

const useConnectedMap = () => {
  const finishEditing = useRef(noop)
  const dispatch = useDispatch()
  const map = useContext(MapContext)
  const { isEditing } = useSelector(mapSelector, shallowEqual)
  const [polylineState, setPolylineState] = useState<PolylineAttributes>(INITIAL_POLYLINE_STATE)
  const [polygonState, setPolygonState] = useState<PolygonAttributes>(INITIAL_POLYGON_STATE)
  const [circleState, setCircleState] = useState<CircleAttributes>(INITIAL_CIRCLE_STATE)

  const dispatchEditAction = (action: (...args: any) => (reset?: boolean) => void) => {
    if (isEditing) return

    const finishPolygon = action()
    dispatch(setIsEditing(true))

    finishEditing.current = (reset?: boolean) => {
      finishPolygon(reset)
      dispatch(setIsEditing(false))
    }

    return finishEditing.current
  }

  const drawCircle = () =>
    dispatchEditAction(() => map.drawCircle(setCircleState))

  const editPlace = (placeId: string) =>
    dispatchEditAction(() => map.editPlace(placeId, setCircleState))

  const drawPolyline = () => dispatchEditAction(() => map.drawPolyline(setPolylineState))

  const drawPolygon = () =>
    dispatchEditAction(() => map.drawPolygon(setPolygonState))

  const editZone = (zoneId: string) =>
    dispatchEditAction(() => map.editZone(zoneId, setPolygonState))

  const triggerEvent = (eventName: string, ...args: any[]) => map.trigger(eventName, ...args)

  useEffect(() => () => {
    // Assumption is that if the component that started editing is unmounted,
    // then keeping the editing alive isn't sensible and will probably lead to
    // bugs in some other location.
    finishEditing.current()
  }, [])

  return {
    circleState,
    drawCircle,
    drawPolygon,
    drawPolyline,
    editPlace,
    editZone,
    isEditing,
    map,
    polygonState,
    polylineState,
    triggerEvent
  }
}

export default useConnectedMap
