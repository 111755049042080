import { Controller } from "stimulus"
import axios from "axios"

class FTPReportsController extends Controller {
  connect() {
    this.load()
  }

  async load() {
    axios.get(this.data.get("path"))
      .then(response => this.element.innerHTML = response.data)
  }
}

export default FTPReportsController
