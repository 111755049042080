import { IDevice } from "api/interfaces/IDevice"
import { IReport } from "api/interfaces/IReport"
import { AxiosRequestConfig } from "axios"

import Resource from "../Resource"

class ReportResource extends Resource<IDevice> {
  get resourcePath() { return "/api/reports" }

  create = (report: IReport, config?: AxiosRequestConfig) =>
    this.post(this.resourcePath, {
      ...report,
      endTime: report.endTime.toISOString(),
      startTime: report.startTime.toISOString()
    }, config)
}

export default ReportResource
