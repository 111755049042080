import { useEffect, useRef } from "react"
import { useStore } from "react-redux"

import { TState } from "tracking/store/store"

/*
 * In some cases we want to react to store updates without triggering a
 * rerender of the underlying components, for example when working with
 * side effects outside of the react component tree, e.g. the map.
 *
 * In that case using useSelector and then useEffect to react to its result is
 * not the weapon of choice, because it will always trigger a full rerender.
 * This behavior is espacially bad and slow in top level components high up in
 * the component tree.
 */
const useStoreSubscription = <TSelected = unknown>(
  selector: (state: TState) => TSelected,
  equalityFn: (left: TSelected, right: TSelected) => boolean,
  callback: (selectorResult: TSelected) => any
) => {
  const previousSelectorResult = useRef<TSelected>()
  const store = useStore()

  useEffect(() => store.subscribe(() => {
    const selectorResult = selector(store.getState())
    const isEqual = equalityFn(selectorResult, previousSelectorResult.current)

    if (isEqual) return

    previousSelectorResult.current = selectorResult
    callback(selectorResult)
  }), [selector, equalityFn, callback])

  useEffect(() => {
    const selectorResult = selector(store.getState())
    previousSelectorResult.current = selectorResult
    callback(selectorResult)
  }, [selector])
}

export default useStoreSubscription
