import Cache from "./Cache"
import { marshalToJson, unmarshalFromJson } from "./marshalling"

class CookieCache extends Cache {
  protected clearCache() {
    document.cookie = `${this.cacheKey}=; Expires=${new Date().toUTCString()};`
  }

  protected readCache() {
    const cookies = document
      .cookie
      .split(/;\s?/)
      .reduce((result, cookie) => {
        const separatorIndex = cookie.search("=")
        const key = cookie.slice(0, separatorIndex)
        const value = cookie.slice(separatorIndex + 1)

        result[key] = decodeURIComponent(value)

        return result
      }, {} as Record<string, string>)

    return unmarshalFromJson(cookies[this.cacheKey])
  }

  protected writeCache() {
    const value = encodeURIComponent(marshalToJson(this.cache))
    const expiryDate = new Date(2200, 0, 1)

    document.cookie = [
      `${this.cacheKey}=${value}`,
      "SameSite=Strict",
      "Secure",
      `Expires=${expiryDate.toUTCString()}`
    ].join("; ")
  }
}

export default CookieCache
