import L from "leaflet"

import { IDevice } from "api/interfaces/IDevice"
import { IPlace } from "api/interfaces/IPlace"
import { ITelemetry } from "api/interfaces/ITelemetry"
import { ITravelSheet } from "api/interfaces/ITravelSheet"
import { IUtilization } from "api/interfaces/IUtilization"
import { IZone } from "api/interfaces/IZone"

import IMap from "./IMap"
import DeviceLayer from "./layers/DeviceLayer"
import ZoneLayer from "./layers/ZoneLayer"
import { drawCircle, ProgressHandler as CircleProgressHandler } from "./circle"
import { drawPolygon, ProgressHandler } from "./polygon"
import { onMapEvent, triggerMapEvent, removeAllEvents } from "./mapEvents"
import TravelSheetLayer from "./layers/TravelSheetLayer"
import UtilizationLayer from "./layers/UtilizationLayer"
import PlaceLayer from "./layers/PlaceLayer"
import { drawPolyline } from "./polyline"

/*
 * This class is a facade for the single layers that make up the whole map.
 */

class Map implements IMap {
  private map: L.Map
  private deviceLayer: DeviceLayer
  private placeLayer: PlaceLayer
  private travelSheetLayer: TravelSheetLayer
  private zoneLayer: ZoneLayer
  private utilizationLayer: UtilizationLayer
  private highlightedDeviceId?: string

  constructor(map: L.Map) {
    this.map = map
    this.deviceLayer = new DeviceLayer(map)
    this.placeLayer = new PlaceLayer(map)
    this.travelSheetLayer = new TravelSheetLayer(map)
    this.utilizationLayer = new UtilizationLayer(map)
    this.zoneLayer = new ZoneLayer(map)
  }

  addTravelSheet(travelSheet: ITravelSheet): void {
    this.travelSheetLayer.add(travelSheet)
  }

  deleteTravelSheet(travelSheetId: string): void {
    this.travelSheetLayer.delete(travelSheetId)
  }

  addDevices(devices: IDevice[]): void {
    this.deviceLayer.addDevices(devices)
  }

  deleteDevices(deviceIds: string[]): void {
    this.deviceLayer.deleteDevices(deviceIds)
  }

  fitDevices(): void {
    this.deviceLayer.fit()
  }

  addPlaces(places: IPlace[]): void {
    this.placeLayer.addPlaces(places)
  }

  deleteAllPlaces(): void {
    this.placeLayer.deleteAllPlaces()
  }

  panToPlace(placeId: string): void {
    this.placeLayer.panToPlace(placeId)
  }

  drawCircle(onProgress?: CircleProgressHandler): (reset?: boolean) => void {
    return drawCircle(this.map, onProgress)
  }

  editPlace(placeId: string, onProgress?: CircleProgressHandler): (reset?: boolean) => void {
    return this.placeLayer.editPlace(placeId, onProgress)
  }

  highlightPlace(placeId: string): void {
    this.placeLayer.highlightPlace(placeId)
  }

  unhighlightPlace(placeId: string): void {
    this.placeLayer.unhighlightPlace(placeId)
  }

  addUtilization(utilization: IUtilization): void {
    this.utilizationLayer.add(utilization)
  }

  deleteUtilization(utilizationId: string): void {
    this.utilizationLayer.delete(utilizationId)
  }

  panToUtilization(utilizationId: string): void {
    this.utilizationLayer.panTo(utilizationId)
  }

  addZones(zones: IZone[]): void {
    this.zoneLayer.addZones(zones)
  }

  deleteAllZones(): void {
    this.zoneLayer.deleteAllZones()
  }

  setTelemetry(telemetry: ITelemetry[]): void {
    this.deviceLayer.setTelemetry(telemetry)
  }

  panToDevice(deviceId: string): void {
    this.deviceLayer.panToDevice(deviceId)
  }

  panToZone(zoneId: string): void {
    this.zoneLayer.panToZone(zoneId)
  }

  highlightZone(zoneId: string): void {
    this.zoneLayer.highlightZone(zoneId)
  }

  unhighlightZone(zoneId: string): void {
    this.zoneLayer.unhighlightZone(zoneId)
  }

  highlightDevice(deviceId: string): void {
    if (this.highlightedDeviceId) {
      this.deviceLayer.unhighlightDevice(deviceId)
    }

    this.deviceLayer.highlightDevice(deviceId)
    this.highlightedDeviceId = deviceId
  }

  unhighlightDevice(deviceId: string): void {
    delete this.highlightedDeviceId
    this.deviceLayer.unhighlightDevice(deviceId)
  }

  hideDevices(deviceIds: string[]): void {
    this.deviceLayer.hideDevices(deviceIds)
  }

  showDevices(deviceIds: string[]): void {
    this.deviceLayer.showDevices(deviceIds)
  }

  // Do not use this method directly, but via the map hook so that the editing
  // status in the store gets updated accordingly. This class is not
  // responsible for checking if drawing another polygon is already taking
  // place.
  drawPolygon(onProgress?: ProgressHandler): () => any {
    return drawPolygon(this.map, onProgress)
  }

  drawPolyline(onProgress?: ProgressHandler): () => any {
    return drawPolyline(this.map, onProgress)
  }

  // Do not use this method directly, but via the map hook so that the editing
  // status in the store gets updated accordingly. This class is not
  // responsible for checking if drawing another polygon is already taking
  // place.
  editZone(zoneId: string, onProgress?: ProgressHandler): () => any {
    return this.zoneLayer.editZone(zoneId, onProgress)
  }

  on(eventName: string, handler: Function): () => void {
    return onMapEvent(this.map, eventName, handler)
  }

  trigger(eventName: string, ...args: any[]): void {
    triggerMapEvent(this.map, eventName, ...args)
  }

  removeAllEvents(): void {
    removeAllEvents(this.map)
  }
}

export default Map
