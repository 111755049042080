import L from "leaflet"

import { ISection } from '../../api/interfaces/ITravelSheet'
import { EventKind } from '../../models/event'

export const EVENT_ICON_HTML_CLASSES: { [event: string]: string } = {
  [EventKind.PLACE_ENTERED]: "fas fa-draw-polygon event-icon-green",
  [EventKind.PLACE_LEFT]: "fas fa-draw-polygon event-icon-red"
}

export const arrow = L.icon({
  iconSize: [32, 32],
  tooltipAnchor: [16, 0],
  iconUrl: "/images/arrow-icon.png"
})

export const arrowHighlighted = L.icon({
  iconSize: [32, 32],
  tooltipAnchor: [16, 0],
  iconUrl: "/images/arrow-highlighted-icon.png"
})

export const stopMarker = L.icon({
  iconAnchor: [24, 48],
  iconSize: [48, 48],
  iconUrl: "/images/stop-marker-icon.png",
  popupAnchor: [0, -48]
})

export const getStopClusterMarker = cluster => L.divIcon({
  className: "map-cluster-marker map-cluster-marker-stop",
  html: `<b>${cluster.getChildCount()}</b>`,
  iconSize: [48, 48]
})

export const getEventClusterMarker = cluster => L.divIcon({
  className: "map-cluster-marker map-cluster-marker-event",
  html: `<b>${cluster.getChildCount()}</b>`,
  iconSize: [48, 48]
})

export const getEventIcon = (event: ISection) => {
  const iconHtmlClass = EVENT_ICON_HTML_CLASSES[event.kind]

  return L.divIcon({
    className: "map-cluster-marker map-cluster-marker-event",
    html: iconHtmlClass ? `<i class="${iconHtmlClass}"></i>` : "<b>?</b>",
    iconAnchor: [24, 48],
    iconSize: [48, 48],
    popupAnchor: [0, -48]
  })
}
