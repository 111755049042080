import L, { LatLngExpression } from "leaflet"

interface CreateMapOptions {
  center: LatLngExpression
  layer: L.Layer
  layers: L.Control.LayersObject
  onCenterChange?: (latLng: number[]) => void
  onLayerChange?: (layerName: string) => void
  onZoomChange?: (zoomLevel: number) => void
  zoom: number
}

declare module "leaflet" {
  interface LeafletLayerChangeEvent extends LeafletEvent {
    name: string
  }

  interface Layer {
    map: L.Map
  }
}

const createMap = (elementId: string | HTMLElement, options: CreateMapOptions) => {
  const {
    center,
    layer,
    layers,
    onCenterChange,
    onLayerChange,
    onZoomChange,
    zoom
  } = options

  const map = L.map(elementId, {
    editable: true,
    center,
    layers: [layer],
    minZoom: 3,
    zoom
  })

  L.control.layers(layers).addTo(map)

  if (onCenterChange) {
    map.on("moveend", e => {
      const { lat, lng } = e.target.getCenter()
      onCenterChange([lat, lng])
    })
  }

  if (onLayerChange) {
    map.on("baselayerchange", (e: L.LeafletLayerChangeEvent) => {
      onLayerChange(e.name)
    })
  }

  if (onZoomChange) {
    map.on("zoomend", e => {
      onZoomChange(e.target.getZoom())
    })
  }

  map.on("layeradd", e => {
    e.layer.map = map
  })

  return map
}

export default createMap
