import React from "react"

import { IDevice } from "api/interfaces/IDevice"
import { IDeviceGroup } from "api/interfaces/IDeviceGroup"
import { TObject } from "utils"

import DeviceList from "./DeviceList"

interface IDeviceSelectProps {
  deviceGroups: TObject<IDeviceGroup>
  onDeviceSelect: (device: IDevice) => any
  onDeviceRemove: (device: IDevice) => any
  onDeviceGroupSelect: (deviceGroup: IDeviceGroup) => any
  onDeviceGroupRemove: (deviceGroup: IDeviceGroup) => any
  selectedDevices: IDevice[]
  unselectedDevices: IDevice[]
}

const DeviceSelect: React.FC<IDeviceSelectProps> = ({
  deviceGroups,
  onDeviceGroupRemove,
  onDeviceGroupSelect,
  onDeviceRemove,
  onDeviceSelect,
  selectedDevices,
  unselectedDevices
}) => (
  <>
    <h2 className="h2 mb-2 pb-0">Auszuwertende Geräte</h2>
    <div className="flex flex-wrap md:flex-nowrap">
      <div className="w-full md:w-1/2 md:mr-1">
        <h1 className="py-2 text-lg font-semibold">Verfügbare Geräte ({unselectedDevices.length})</h1>
        <DeviceList
          deviceGroups={deviceGroups}
          devices={unselectedDevices}
          onDeviceClick={onDeviceSelect}
          onDeviceGroupClick={onDeviceGroupSelect}
          searchable
        />
      </div>
      <div className="w-full md:w-1/2 md:ml-1">
        <h1 className="py-2 text-lg font-semibold">Gewählte Geräte ({selectedDevices.length})</h1>
        <DeviceList
          deviceGroups={deviceGroups}
          devices={selectedDevices}
          onDeviceClick={onDeviceRemove}
          onDeviceGroupClick={onDeviceGroupRemove}
        />
      </div>
    </div>
  </>
)

export default DeviceSelect
