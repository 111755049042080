import { ResourcesState } from "./reduxResourcesReducer"

type TResourcesState = Partial<{
  resources: ResourcesState
}>

export const allCashboxWarehousesSelector = (state: TResourcesState) => state.resources.cashboxWarehouses
export const allCashboxProductBalancesSelector = (state: TResourcesState) => state.resources.cashboxProductBalances
export const allDevicesSelector = (state: TResourcesState) => state.resources.devices
export const allDeviceGroupsSelector = (state: TResourcesState) => state.resources.deviceGroups
export const allDeviceTelemetrySelector = (state: TResourcesState) => state.resources.deviceTelemetry
export const allEventTriggersSelector = (state: TResourcesState) => state.resources.eventTriggers
export const allTravelSheetsSelector = (state: TResourcesState) => state.resources.travelSheets
export const allPlacesSelector = (state: TResourcesState) => state.resources.places
export const allSensorsSelector = (state: TResourcesState) => state.resources.sensors
export const allUsersSelector = (state: TResourcesState) => state.resources.users
export const allUtilizationsSelector = (state: TResourcesState) => state.resources.utilizations
export const allZonesSelector = (state: TResourcesState) => state.resources.zones
