const matchFuzzy = (string: string, searchTerm: string): number => {
  string = string.toLowerCase()
  searchTerm = searchTerm.toLowerCase()

  if (string === searchTerm) return 1

  const letters = searchTerm.split('')
  const letterCount = letters.length
  const indeces = []

  let index = 0

  for (let i = 0; i < letterCount; i++) {
    const letter = letters[i]

    if (letter === ' ') continue

    index = string.indexOf(letter, index)

    if (index === -1) return 0

    indeces.push(index)
    index++
  }

  return indeces.reduce((a, b) => a + b, 2)
}

export default matchFuzzy
