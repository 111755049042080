import L from "leaflet"

import DeviceMarker from "./DeviceMarker"

class DeviceTooltip {
  private _isHighlighted = false
  private _text = ""
  private marker: L.Marker
  private tooltip?: L.Tooltip

  constructor(marker: L.Marker) {
    this.marker = marker
  }

  set text(value: string) {
    this._text = value
    this.updateTooltip()
  }

  highlight() {
    this._isHighlighted = true
    this.updateTooltip()
    this.tooltip?.bringToFront()
  }

  unhighlight() {
    this._isHighlighted = false
    this.updateTooltip()
  }

  private updateTooltip() {
    const className = this._isHighlighted ? "map-tooltip highlighted" : "map-tooltip"

    this.tooltip = L.tooltip({ className, permanent: true })
    this.tooltip.setContent(this._text)
    this.marker.unbindTooltip()
    this.marker.bindTooltip(this.tooltip)
  }
}

export default DeviceTooltip
