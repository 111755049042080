import React, { useEffect, useRef, useState } from "react"
import { shallowEqual, useStore } from "react-redux"
import { difference, filter, intersection, isEmpty, map as lodashMap, pickBy, union } from "lodash"

import { IDevice } from "api/interfaces/IDevice"
import { ITravelSheet } from "api/interfaces/ITravelSheet"
import { useStoreSubscription } from "hooks"
import createMapFromConfig from "tracking/map/createMapFromConfig"
import Map from "tracking/map/Map"
import { allDevicesSelector, allDeviceTelemetrySelector, allPlacesSelector, allTravelSheetsSelector, allZonesSelector } from "api/reduxResourcesSelectors"
import { TState } from "tracking/store/store"
import { diffArrays, TObject } from "utils"
import { selectedDeviceIdsSelector } from "tracking/pages/TrackingPage/selectors"
import { createStructuredSelector } from "reselect"

const MAP_ELEMENT_CLASS_LIST = ["map", "w-full", "h-full", "shadow", "rounded-md", "z-0"]

interface IuseCreateConnectedMap {
  devicesSelector?: (state: TState) => TObject<IDevice>
}

const useCreateConnectedMap = ({ devicesSelector }: IuseCreateConnectedMap = {}) => {
  const cachedSelectedDeviceIds = useRef<string[]>([])
  const cachedDevices = useRef<TObject<IDevice>>({})
  const cachedDeviceTelemetry = useRef({})
  const cachedTravelSheets = useRef<TObject<ITravelSheet>>({})
  const [element] = useState(() => document.createElement("div"))
  const [map] = useState(() => new Map(createMapFromConfig(element)))
  const store = useStore()

  useStoreSubscription(devicesSelector || allDevicesSelector, shallowEqual, devices => {
    const currentDevices = Object.values(cachedDevices.current)
    const nextDevices = Object.values(devices)
    const { addedItems, deletedItems } = diffArrays(currentDevices, nextDevices)

    map.addDevices(addedItems)
    map.deleteDevices(lodashMap(deletedItems, "id"))

    const selectedDeviceIds = Object.keys(pickBy(selectedDeviceIdsSelector(store.getState()), isSelected => isSelected === true))
    const hiddenDeviceIds = difference(lodashMap(addedItems, "id"), selectedDeviceIds)

    map.hideDevices(hiddenDeviceIds)

    cachedDevices.current = devices
  })

  useStoreSubscription(selectedDeviceIdsSelector, shallowEqual, selectedDeviceIds => {
    const nextDeviceIds = Object.keys(pickBy(selectedDeviceIds, isSelected => isSelected === true))
    const { addedItems, deletedItems } = diffArrays(cachedSelectedDeviceIds.current, nextDeviceIds)

    map.showDevices(addedItems)
    map.hideDevices(deletedItems)

    cachedSelectedDeviceIds.current = nextDeviceIds
  })

  useStoreSubscription(allPlacesSelector, shallowEqual, places => {
    map.deleteAllPlaces()
    map.addPlaces(Object.values(places))
  })

  useStoreSubscription(allZonesSelector, shallowEqual, zones => {
    map.deleteAllZones()
    map.addZones(Object.values(zones))
  })

  useStoreSubscription(allDeviceTelemetrySelector, shallowEqual, deviceTelemetry => {
    const telemetryToUpdate = []

    for (const [deviceId, telemetry] of Object.entries(deviceTelemetry)) {
      if (cachedDeviceTelemetry.current[deviceId] !== telemetry) {
        telemetryToUpdate.push(telemetry)
      }
    }

    map.setTelemetry(telemetryToUpdate)
    cachedDeviceTelemetry.current = deviceTelemetry
  })

  useStoreSubscription(allTravelSheetsSelector, shallowEqual, travelSheets => {
    const currentSheets = Object.values(cachedTravelSheets.current)
    const nextSheets = Object.values(travelSheets)
    const { addedItems, deletedItems } = diffArrays(currentSheets, nextSheets)

    for (const item of addedItems) {
      map.addTravelSheet(item)
    }

    for (const item of deletedItems) {
      map.deleteTravelSheet(item.id)
    }

    cachedTravelSheets.current = travelSheets
  })

  useEffect(() => {
    element.classList.add(...MAP_ELEMENT_CLASS_LIST)
  }, [])

  useEffect(() => {
    // Sometimes the map will bug and display a huge gray area and only a tiny
    // bit of actual map. In this case, a window resize restores the correct
    // map behavior.
    const interval = setInterval(() => {
      window.dispatchEvent(new Event("resize"))
    }, 500)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => () => {
    map.removeAllEvents()
  }, [])

  return {
    element: <div className="w-full h-full" ref={ref => ref && ref.appendChild(element)} />,
    map
  }
}

export default useCreateConnectedMap
