import { Controller } from "stimulus"

class NavbarController extends Controller {
  hideMobileNavigation = () => {
    this.element.classList.remove("Navbar__Container--open")
    this.element.removeEventListener("click", this.hideMobileNavigation)
  }

  showMobileNavigation = (e: MouseEvent) => {
    e.stopPropagation()

    this.element.classList.add("Navbar__Container--open")
    this.element.addEventListener("click", this.hideMobileNavigation)
  }
}

export default NavbarController
