import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { formatDateTime, formatDuration } from "utils/date"

const StopPopup = ({ addressPromise, arrival, departure, duration }) => {
  const [addressText, setAddressText] = useState("Lädt...")

  useEffect(() => {
    addressPromise
      .then(setAddressText)
      .catch(e => {
        setAddressText("Nicht lokalisierbar")
        throw e
      })
  }, [addressPromise])

  return (
    <table className="popup-table">
      <tbody>
        <tr>
          <td>Ankunft</td>
          <td>{formatDateTime(arrival)}</td>
        </tr>
        <tr>
          <td>Abfahrt</td>
          <td>{formatDateTime(departure)}</td>
        </tr>
        <tr>
          <td>Dauer</td>
          <td>{formatDuration(duration)}</td>
        </tr>
        <tr>
          <td>Adresse</td>
          <td>{addressText}</td>
        </tr>
      </tbody>
    </table>
  )
}

StopPopup.propTypes = {
  addressPromise: PropTypes.instanceOf(Promise),
  arrival: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  departure: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  duration: PropTypes.number.isRequired
}

export default StopPopup
