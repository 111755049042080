import { AxiosError } from "axios";

class ResourceError {
  public data?: any
  public status?: number

  static buildFromAxiosError(axiosError: AxiosError) {
    const error = new ResourceError()
    error.data = axiosError.response.data
    error.status = axiosError.response?.status
    return error
  }
}

export default ResourceError
