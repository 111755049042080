import React from "react"

interface IReoccurrenceProps {
  name: string
  nameError?: string
  onNameChange: (name: string) => any
  onReoccurDailyChange: (reoccurDaily: boolean) => any
  onReoccurWeeklyChange: (reoccurWeekly: boolean) => any
  onReoccurMonthlyChange: (reoccurMonthly: boolean) => any
  reoccurDaily: boolean
  reoccurDailyError?: string
  reoccurWeekly: boolean
  reoccurWeeklyError?: string
  reoccurMonthly: boolean
  reoccurMonthlyError?: string
}


const Reoccurrence: React.FC<IReoccurrenceProps> = ({
  name,
  nameError,
  onNameChange,
  onReoccurDailyChange,
  onReoccurWeeklyChange,
  onReoccurMonthlyChange,
  reoccurDaily,
  reoccurDailyError,
  reoccurWeekly,
  reoccurWeeklyError,
  reoccurMonthly,
  reoccurMonthlyError,
}) => {
  const reoccur = reoccurDaily || reoccurWeekly || reoccurMonthly

  return (
    <>
      <h2 className="h2 mb-2 pb-0">Wiederkehrender Bericht</h2>
      {reoccur && (
        <>
          <label htmlFor="reoccur-name" className="text-sm font-semibold">Name</label>
          <input id="reoccur-name" type="text" className={`text-input${nameError ? " border-red-600" : ""}`} onChange={e => onNameChange(e.target.value)} value={name} />
          {nameError && (
            <div className="text-red-600 text-sm">{nameError}</div>
          )}
        </>
      )}
      <div className="flex flex-wrap md:flex-nowrap">
        <div className="py-2 w-full md:w-1/2 flex flex-col">
          <div className="flex items-center">
            <input id="reoccur-daily" onChange={e => onReoccurDailyChange(e.target.checked)} type="checkbox" checked={reoccurDaily} />
            <label htmlFor="reoccur-daily" className="ml-1">Täglich generieren</label>
          </div>
          {reoccurDailyError && (
            <div className="text-red-600 text-sm">{reoccurDailyError}</div>
          )}
        </div>
        <div className="py-2 w-full md:w-1/2 md:ml-2 flex flex-col">
          <div className="flex items-center">
            <input id="reoccur-weekly" onChange={e => onReoccurWeeklyChange(e.target.checked)} type="checkbox" checked={reoccurWeekly} />
            <label htmlFor="reoccur-weekly" className="ml-1">Wöchentlich generieren</label>
          </div>
          {reoccurWeeklyError && (
            <div className="text-red-600 text-sm">{reoccurWeeklyError}</div>
          )}
        </div>
        <div className="py-2 w-full md:w-1/2 md:ml-2 flex flex-col">
          <div className="flex items-center">
            <input id="reoccur-monthly" onChange={e => onReoccurMonthlyChange(e.target.checked)} type="checkbox" checked={reoccurMonthly} />
            <label htmlFor="reoccur-monthly" className="ml-1">Monatlich generieren</label>
          </div>
          {reoccurMonthlyError && (
            <div className="text-red-600 text-sm">{reoccurMonthlyError}</div>
          )}
        </div>
      </div>
    </>
  )
}

export default Reoccurrence
