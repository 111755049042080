import { IRawSection, IRawTravelSheet, ISection, ITravelSheet, IRawDay, IDay } from "./interfaces/ITravelSheet"
import { IRawUtilizationDetails, IUtilizationDetails } from "./interfaces/IUtilization"

const humanizeTripSectionKeys = (section: IRawSection): ISection => {
  if (section.type === "drive") return humanizeDriveKeys(section)
  if (section.type === "stop") return humanizeStopKeys(section)
  if (section.type === "event") return humanizeEventKeys(section)

  throw new Error(`Unknown section type ${section.type}`)
}

const humanizeDriveKeys = (drive: IRawSection): ISection => ({
  averageSpeedInKmh: drive.vAvg,
  durationInSeconds: drive.t,
  endTime: drive.t2,
  id: drive.id,
  lengthInMeters: drive.l,
  maxSpeedInKmh: drive.vMax,
  points: drive.p.map(([lat, lng]) => ({ lat, lng })),
  startTime: drive.t1,
  type: drive.type
})

const humanizeStopKeys = (stop: IRawSection): ISection => ({
  durationInSeconds: stop.t,
  endTime: stop.t2,
  id: stop.id,
  points: stop.p.map(([lat, lng]) => ({ lat, lng })),
  startTime: stop.t1,
  type: stop.type
})

const humanizeEventKeys = (event: IRawSection): ISection => ({
  durationInSeconds: 0,
  endTime: event.t2,
  id: event.id,
  kind: event.k,
  kindText: event.kT,
  lat: event.lat,
  lng: event.lng,
  points: [],
  startTime: event.t1,
  type: event.type,
  value: event.v
})

const humanizeTripDayKeys = (day: IRawDay): IDay => ({
  id: day.id,
  date: day.date,
  sections: day.sections.map(humanizeTripSectionKeys)
})

export const humanizeTripKeys = (trip: IRawTravelSheet): ITravelSheet => ({
  averageSpeedInKmh: trip.vAvg,
  days: trip.days.map(humanizeTripDayKeys),
  deviceId: trip.deviceId,
  driveDurationInSeconds: trip.tDrives,
  endTime: trip.t2,
  id: trip.id,
  lengthInMeters: trip.l,
  maxSpeedInKmh: trip.vMax,
  startTime: trip.t1
})

export const transformUtilizationDetails = (utilizations: IRawUtilizationDetails[]): IUtilizationDetails[] => utilizations.map(utilization => ({
  ...utilization,
  points: utilization.points.map(points => {
    return points.map(([lng, lat]) => ({ lat, lng }))
  })
}))
