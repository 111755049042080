import { debounce, noop } from "lodash"

export type ProgressHandler = (attributes: PolylineAttributes) => any
export interface PolylineAttributes {
  coordinates: number[][]
}

const addPolylineEventHandlers = (polyline: L.Polyline, progressHandler: (...args: any[]) => void) => {
  const debouncedProgressHandler = debounce(progressHandler, 100)

  polyline.on("editable:editing", debouncedProgressHandler)

  return () => {
    polyline.off("editable:editing", debouncedProgressHandler)
  }
}

const getLeafletProgressHandler = (polyline: L.Polyline, onProgress?: ProgressHandler) => {
  if (!onProgress) return noop

  return () => {
    const latlngs = polyline.getLatLngs() as L.LatLng[]

    onProgress({
      coordinates: latlngs.map(x => ([x.lng, x.lat]))
    })
  }
}

export const drawPolyline = (map: L.Map, onProgress?: ProgressHandler) => {
  const polyline = map.editTools.startPolyline.call(map.editTools)
  const progressHandler = getLeafletProgressHandler(polyline, onProgress)
  const removeEventHandlers = addPolylineEventHandlers(polyline, progressHandler)

  // Pass the initial progress of the existing polyline to the progress handler.
  progressHandler()

  return (reset: boolean = true) => {
    removeEventHandlers()
    polyline.disableEdit()

    if (reset) {
      polyline.removeFrom(map)
    }
  }
}

