import { useState } from "react"

const CLASS_NAME = "p-2 md:p-4 border-b-2 font-semibold cursor-pointer"
const CLASS_NAME_ACTIVE = CLASS_NAME + " border-green-500 cursor-default"

const useTabs = () => {
  const [activeTab, setActiveTab] = useState(0)

  const isActiveTab = (tab: number) => tab === activeTab
  const getTabProps = (tab: number) => ({
    className: isActiveTab(tab) ? CLASS_NAME_ACTIVE : CLASS_NAME,
    onClick: () => setActiveTab(tab)
  })

  return {
    getTabProps,
    isActiveTab,
    setActiveTab
  }
}

export default useTabs
