
import { IGoogleDrive, IGoogleFile } from "../interfaces/IGoogle"
import Resource from "../Resource"

interface IcreateSheet {
  name: string
}

interface IgetSheets {
  pageToken?: string
}

class GoogleResource extends Resource<any> {
  get resourcePath() { return "/api/google" }

  createEmptySheet = ({ name }: IcreateSheet): Promise<IGoogleFile> =>
    this.post(`${this.resourcePath}/sheets`, { name })

  getSheets = ({ pageToken }: IgetSheets = {}): Promise<IGoogleDrive> => {
    const params = {
      mimeType: "application/vnd.google-apps.spreadsheet",
      parent: "root",
      ...pageToken && { pageToken }
    }

    return this.get(`${this.resourcePath}/drive`, { params })
  }
}

export default GoogleResource
