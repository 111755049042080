import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"

import CurrentTelemetryFetcher, { FETCH_INTERVAL } from "tracking/CurrentTelemetryFetcher"
import FlashMessages from "FlashMessages"
import { useInterval } from "hooks"

interface IRetryMessageProps {
  onRetryCountdownFinished: () => void
}

const UNIQUE_FLASH_MESSAGE_NAME = "telemetry-fetching-error"
const FETCH_INTERVAL_IN_SECONDS = Math.round(FETCH_INTERVAL / 1000)

const RetryMessage: React.FunctionComponent<IRetryMessageProps> = ({ onRetryCountdownFinished }) => {
  const [retryTime, setRetryTime] = useState(FETCH_INTERVAL_IN_SECONDS)
  const isFinished = retryTime <= 0

  useInterval(() => {
    setRetryTime(state => state - 1)
  }, isFinished ? null : 1000)

  useEffect(() => {
    if (isFinished) {
      onRetryCountdownFinished()
    }
  }, [isFinished])
  return (
    <>
      Die Verbindung zum Server wurde verloren und Ihre Geräte werden nicht mehr aktuell dargestellt. Neuer Verbindungsversuch in {retryTime} Sekunde(n).
    </>
  )
}

const currentTelemetryErrorHandler = (e: Error, currentTelemetryFetcher: CurrentTelemetryFetcher) => {
  const div: HTMLElement = document.createElement("div")
  const handleRetryCountdownFinished = async () => {
    FlashMessages.removeUniqueMessage(UNIQUE_FLASH_MESSAGE_NAME)
    currentTelemetryFetcher.start()

    await currentTelemetryFetcher.initialFetchPromise

    FlashMessages.addMessage("notice", "Verbindung wiederhergestellt.")
  }

  ReactDOM.render(<RetryMessage onRetryCountdownFinished={handleRetryCountdownFinished} />, div)

  FlashMessages.addMessage("alert", div, { uniqueName: UNIQUE_FLASH_MESSAGE_NAME, permanent: true })
}

export const currentTelemetryErrorHandlerRedux = (fetch: () => any) => {
  const div: HTMLElement = document.createElement("div")
  const handleRetryCountdownFinished = async () => {
    FlashMessages.removeUniqueMessage(UNIQUE_FLASH_MESSAGE_NAME)
    await fetch()

    FlashMessages.addMessage("notice", "Verbindung wiederhergestellt.")
  }

  ReactDOM.render(<RetryMessage onRetryCountdownFinished={handleRetryCountdownFinished} />, div)

  FlashMessages.addMessage("alert", div, { uniqueName: UNIQUE_FLASH_MESSAGE_NAME, permanent: true })

}

export default currentTelemetryErrorHandler
