import React from "react"
import ReactDOM from "react-dom"
import L from "leaflet"

import { ISection } from "api/interfaces/ITravelSheet"
import { DrivePopup } from "components/layers"
import { MAP_ENTITY_COLOR_HOVER } from "tracking/map/constants"

import { IDrawer } from "./types"
import { getSectionEventName, onMapEvent } from "tracking/map/mapEvents"

class Drive implements IDrawer {
  private color: string
  private parentLayer: L.FeatureGroup
  private section: ISection
  private polyline: L.Polyline
  private unsubscribeFromEvents: Function

  constructor(parentLayer: L.FeatureGroup, section: ISection, color: string) {
    this.color = color
    this.parentLayer = parentLayer
    this.section = section
    this.polyline = L.polyline(section.points, { color })
    this.polyline
      .bindPopup(this.getPopup())
      .on("mouseover", () => this.highlight())
      .on("mouseout", () => this.unhighlight())
      .addTo(parentLayer)

    this.unsubscribeFromEvents = this.subscribeToEvents()
  }

  delete() {
    this.unsubscribeFromEvents()
  }

  highlight() {
    this.polyline.setStyle({ color: MAP_ENTITY_COLOR_HOVER })
    this.polyline.bringToFront()
  }

  unhighlight() {
    this.polyline.setStyle({ color: this.color })
  }

  bringToCenter() {
    const bounds = this.polyline.getBounds()

    if (bounds.isValid()) {
      this.parentLayer.map.fitBounds(bounds)
    }

    this.polyline.bringToFront()
  }

  openPopup() {
    this.polyline.openPopup()
  }

  private subscribeToEvents() {
    const unsubscribeMouseOver = onMapEvent(this.parentLayer.map, getSectionEventName("mouseover", this.section.id), () => {
      this.highlight()
    })

    const unsubscribeMouseOut = onMapEvent(this.parentLayer.map, getSectionEventName("mouseout", this.section.id), () => {
      this.unhighlight()
    })

    const unsubscribeClick = onMapEvent(this.parentLayer.map, getSectionEventName("click", this.section.id), () => {
      this.bringToCenter()
      this.openPopup()
    })

    return () => {
      unsubscribeMouseOut()
      unsubscribeMouseOver()
      unsubscribeClick()
    }
  }

  private getPopup = () => {
    const popup = L.popup()

    popup.setContent(() => {
      const element = document.createElement("div")

      ReactDOM.render(
        <DrivePopup
          arrival={this.section.endTime}
          averageSpeed={this.section.averageSpeedInKmh}
          departure={this.section.startTime}
          duration={this.section.durationInSeconds}
          length={this.section.lengthInMeters}
          maxSpeed={this.section.maxSpeedInKmh}
        />, element)

      return element
    })

    return popup
  }
}

export default Drive
