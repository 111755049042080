import { useContext, useEffect } from "react"

import MapContext from "tracking/map/MapContext"

const useConnectedMapEvent = (eventName: string, handler: Function) => {
  const map = useContext(MapContext)

  useEffect(() => map.on(eventName, handler), [])
}

export default useConnectedMapEvent
