import React from "react"
import { map } from "lodash"

import Timepicker from "components/Timepicker"

import { ITimePeriod } from "./types"

export enum TimePeriodValue {
  TODAY = "today",
  YESTERDAY = "yesterday",
  TWO_DAYS_AGO = "twoDaysAgo",
  THREE_DAYS_AGO = "threeDaysAgo",
  LAST_WEEK = "lastWeek",
  ONE_WEEK_AGO = "oneWeekAgo",
  CUSTOM = "custom"
}

const TIME_PERIOD_TRANSLATIONS = {
  [TimePeriodValue.TODAY]: "Heute",
  [TimePeriodValue.YESTERDAY]: "Gestern",
  [TimePeriodValue.TWO_DAYS_AGO]: "Vorgestern",
  [TimePeriodValue.THREE_DAYS_AGO]: "Vor drei Tagen",
  [TimePeriodValue.LAST_WEEK]: "Vergangene Woche",
  [TimePeriodValue.ONE_WEEK_AGO]: "Letzte Woche",
  [TimePeriodValue.CUSTOM]: "Benutzerdefiniert"
}

interface ITimePeriodSelector extends ITimePeriod {
  onChange: (timePeriod: Partial<ITimePeriod>) => void
}

const TimePeriodSelector: React.FunctionComponent<ITimePeriodSelector> = ({ endTime, onChange, startTime, value }) => (
  <div className="w-full flex flex-col">
    <label className="text-sm font-semibold" htmlFor="time-period-select">Zeitraum</label>
    <select className="text-input" id="time-period-select" onChange={e => onChange({ value: (e.target.value as TimePeriodValue) })} value={value}>
      {map(TimePeriodValue, value => (
        <option key={value} value={value} label={TIME_PERIOD_TRANSLATIONS[value]} />
      ))}
    </select>
    {value === TimePeriodValue.CUSTOM && (
      <>
        <Timepicker label="Von" onChange={startTime => onChange({ startTime })} value={startTime} />
        <Timepicker label="Bis" onChange={endTime => onChange({ endTime })} value={endTime} />
      </>
    )}
  </div>
)

export default TimePeriodSelector
