import { AxiosRequestConfig } from "axios"

import { IDeviceGroup, IUnpersistedDeviceGroup } from "../interfaces/IDeviceGroup"
import Resource from "../Resource"

class DeviceGroupResource extends Resource<IDeviceGroup> {
  get resourcePath() { return "/api/device_groups" }

  create = (data: IUnpersistedDeviceGroup, config?: AxiosRequestConfig): Promise<IDeviceGroup> =>
    this.post<IUnpersistedDeviceGroup, IDeviceGroup>(this.resourcePath, data, config)
}

export default DeviceGroupResource
