import { useEffect } from "react"

const useKeyListener = (key: string, handler: (e?: KeyboardEvent) => void) => {
  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.code === key || e.key === key) handler(e)
    }

    window.addEventListener("keydown", keydownHandler)

    return () => {
      window.removeEventListener("keydown", keydownHandler)
    }
  }, [handler])
}

export default useKeyListener
