import { useEffect, useRef } from "react"

const useInterval = (handler: () => void, frequency: number) => {
  const savedHandler = useRef<() => void>()

  useEffect(() => {
    savedHandler.current = handler
  })

  useEffect(() => {
    if (frequency == null) return

    const interval = setInterval(() => {
      savedHandler.current()
    }, frequency)

    return () => clearInterval(interval)
  }, [frequency])
}

export default useInterval
