type TFlashMessageType = "alert" | "notice"

interface IFlashMessageOptions {
  permanent?: boolean
  uniqueName?: string
}

const ICON_NOTICE = `
  <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-8 h-8">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
`

const ICON_ALERT = `
  <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-8 h-8">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
`
const getTemplate = (iconHtml: string, classes: string) => `
  <div
    class="mt-2 w-full md:w-auto md:max-w-1/2 text-white font-semibold rounded-md shadow-sm overflow-hidden flex items-center ${classes}"
    data-controller="flash"
  >
    <div class="w-12 h-12 flex justify-center items-center flex-shrink-0">
      ${iconHtml}
    </div>
    <div class="p-2 content">
    </div>
  </div>
`

const buildFlashElement = (type: TFlashMessageType, content: HTMLElement | string, { permanent, uniqueName }: IFlashMessageOptions) => {
  const tempElement = document.createElement("div")

  if (type === "alert") {
    tempElement.innerHTML = getTemplate(ICON_ALERT, "bg-red-500")
  } else {
    tempElement.innerHTML = getTemplate(ICON_NOTICE, "bg-blue-500")
  }

  const element = tempElement.firstElementChild as HTMLElement

  if (uniqueName) element.setAttribute("data-flash-unique-name-value", uniqueName)
  if (permanent) element.setAttribute("data-flash-is-permanent-value", "true")
  if (typeof content === "string") {
    element.getElementsByClassName("content")[0].innerHTML = content
  } else {
    element.getElementsByClassName("content")[0].appendChild(content)
  }

  return element
}

export class FlashMessages {
  uniqueMessages = new Map<string, HTMLElement>()

  addMessage(type: TFlashMessageType, content: HTMLElement | string, options: IFlashMessageOptions = {}) {
    const { uniqueName } = options
    const element = buildFlashElement(type, content, options)

    if (uniqueName) {
      this.removeUniqueMessage(uniqueName)
      this.uniqueMessages.set(uniqueName, element)
    }

    document.getElementById("flash-messages").appendChild(element)
  }

  removeUniqueMessage(uniqueName: string) {
    const uniqueElement = this.uniqueMessages.get(uniqueName)

    if (uniqueElement) {
      uniqueElement.remove()
    }
  }
}

export default new FlashMessages()
