import React, { useEffect, useMemo, useState } from "react"

import { IDevice } from "api/interfaces/IDevice"
import { IDeviceGroup } from "api/interfaces/IDeviceGroup"
import { useDebouncedState } from "hooks"
import { matchFuzzy, TObject } from "utils"
import { groupBy, map, orderBy, transform } from "lodash"

interface IDeviceListProps {
  devices: IDevice[]
  deviceGroups: TObject<IDeviceGroup>
  onDeviceClick: (device: IDevice) => any
  onDeviceGroupClick: (deviceGroup?: IDeviceGroup) => any
  searchable?: boolean
}

const DeviceList: React.FC<IDeviceListProps> = ({ devices, deviceGroups, onDeviceClick, onDeviceGroupClick, searchable }) => {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useDebouncedState("")
  const filteredDevices = useMemo(() => {
    const trimmedSearchTerm = debouncedSearchTerm.trim()

    if (!trimmedSearchTerm) return devices

    const result = [] as IDevice[]

    devices.forEach(device => {
      if (matchFuzzy(device.name, trimmedSearchTerm)) {
        result.push(device)
      }
    })

    return result
  }, [devices, debouncedSearchTerm])
  const groupedFilteredDevices: TObject<IDevice[]> = useMemo(() => (
    transform(groupBy(filteredDevices, "deviceGroupId"), (result, devices, deviceGroupId) => {
      result[deviceGroupId] = orderBy(devices, "name")
    }, {})
  ), [filteredDevices, deviceGroups])

  useEffect(() => {
    if (!searchable) setSearchTerm("")
  }, [searchable])

  return (
    <div className="max-h-96 flex flex-col">
      {searchable && (
        <input
          className="text-input"
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Gerät suchen ..."
          type="text"
          value={searchTerm}
        />
      )}
      <div className="overflow-y-scroll">
        {map(groupedFilteredDevices, (devices, deviceGroupId) => (
          <React.Fragment key={deviceGroupId}>
            <h2 className="p-1 hover:bg-gray-100 text-lg font-semibold rounded-md cursor-pointer" onClick={() => onDeviceGroupClick(deviceGroups[deviceGroupId])}>
              {deviceGroups[deviceGroupId]?.name || "Ungruppiert"}
            </h2>
            {map(devices, device => (
              <div
                className="py-1 px-2 hover:bg-gray-100 rounded-md cursor-pointer"
                key={device.id}
                onClick={() => onDeviceClick(device)}
              >
                {device.name}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default DeviceList
