import { useEffect, useRef, useState } from "react"

import { currentTelemetryErrorHandlerRedux } from "tracking/currentTelemetryErrorHandler"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { allDevicesSelector } from "api/reduxResourcesSelectors"
import { TState } from "tracking/store/store"
import { Device } from "api/reduxActionCreators"
import { isEmpty, orderBy } from "lodash"
import { ITelemetry } from "api/interfaces/ITelemetry"

const FETCH_INTERVAL = 8000

const useFetchCurrentTelemetryRedux = () => {
  const [isLoadingInitially, setIsLoadingInitially] = useState(true)
  const dispatch = useDispatch()
  const deviceIds = useSelector((state: TState) => Object.keys(allDevicesSelector(state)), shallowEqual)
  const mostRecentUpdateTimestamp = useRef<string>()
  const unmounted = useRef(false)
  const timeout = useRef<NodeJS.Timeout>()

  const fetch = async () => {
    const telemetry: ITelemetry[] = await dispatch(
      Device.currentTelemetry({
        args: {
          ...!isEmpty(deviceIds) && { deviceIds },
          ...mostRecentUpdateTimestamp.current && { updatedAtGt: mostRecentUpdateTimestamp.current }
        }
      })
    ).responsePromise

    const mostRecentTelemetry = orderBy(telemetry, "updatedAt", "desc")[0]

    if (mostRecentTelemetry) {
      mostRecentUpdateTimestamp.current = mostRecentTelemetry.updatedAt
    }
  }

  const fetchInterval = async (initial: boolean = false) => {
    if (unmounted.current) return

    if (initial) {
      setIsLoadingInitially(true)
    }

    try {
      await fetch()
      timeout.current = setTimeout(fetchInterval, FETCH_INTERVAL)
    } catch (e) {
      currentTelemetryErrorHandlerRedux(fetchInterval.bind(null, initial))
      throw e
    }

    if (initial) {
      setIsLoadingInitially(false)
    }
  }

  useEffect(() => {
    fetchInterval(true)

    return () => {
      clearTimeout(timeout.current)
      unmounted.current = true
    }
  }, [])

  return {
    isLoadingInitially
  }
}

export default useFetchCurrentTelemetryRedux
