import React from "react"
import ReactDOM from "react-dom"
import { Controller } from "stimulus"

import { ReportForm } from "components/ReportForm"

import { isPreview } from "../../utils/turbolinks"

class ReportController extends Controller {
  connect() {
    if (isPreview()) return
    ReactDOM.render(<ReportForm />, this.element)
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element)
  }
}

export default ReportController
