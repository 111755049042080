export { default as matchFuzzy } from "./matchFuzzy"
export { default as isObjectEmpty } from "./isObjectEmpty"
export { default as diffArrays } from "./diffArrays"

export type TObject<T> = {[key: string]: T}
export interface ISelectOption {
  label: string
  value: string
}

export const size = (collection: any): number => {
  if (collection == null) return 0
  if (Array.isArray(collection)) return collection.length
  if (typeof collection === "string") return collection.trim().length
  if (collection.constructor === Map || collection.constructor === Set) return collection.size
  if (typeof collection == "object") return Object.keys(collection).length
  return 0
}

export const isEmpty = (collection: any): boolean => size(collection) === 0

export const isBlank = (value: any): boolean => {
  if (value == null) return true
  if (typeof value === "number") return isNaN(value)
  if (typeof value === "string") return value.trim() === ""
  if (Array.isArray(value)) return value.length === 0
  if (typeof value === "object") return Object.keys(value).length === 0
  return true
}
export const isPresent = (value: any): boolean => !isBlank(value)

interface IResource {
  id: string
}

export const resourceArrayToHash = <T extends IResource>(resources: T[]) => {
  const result = {} as TObject<T>

  resources.forEach(resource => {
    result[resource.id] = resource
  })

  return result
}
