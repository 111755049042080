import React from "react"
import { filter, without } from "lodash"

import { IDevice } from "api/interfaces/IDevice"
import { IDeviceGroup } from "api/interfaces/IDeviceGroup"
import Spinner from "components/Spinner"
import { IReportFormState, IReportFormStateErrors } from "hooks/useReportFormState"
import { TObject } from "utils"

import DeviceSelect from "./components/DeviceSelect"
import ReportKind from "./components/ReportKind"
import ReportTimeRange from "./components/ReportTimeRange"
import Reoccurrence from "./components/Reoccurrence"

interface IReportFormProps {
  deviceGroups: TObject<IDeviceGroup>
  devices: IDevice[]
  errors: IReportFormStateErrors
  formats: string[]
  isLoading?: boolean
  onStateChange: (stateSlice: Partial<IReportFormState>) => any
  onSubmit: () => any
  showTimeRange?: boolean
  state: IReportFormState
  submitLabel: string
  submittable: boolean
}

const ReportForm: React.FunctionComponent<IReportFormProps> = ({
  deviceGroups,
  devices,
  errors,
  isLoading,
  onStateChange,
  onSubmit,
  showTimeRange,
  state,
  submitLabel,
  submittable
}) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit()
  }

  if (isLoading) return <Spinner />

  return (
    <form onSubmit={handleSubmit}>
      <div className="p-4 text-base bg-white rounded-md shadow">
        <ReportKind kind={state.kind} onChange={kind => onStateChange({ kind })} />
        {showTimeRange && (
          <ReportTimeRange
            endTime={state.endTime}
            endTimeError={errors.endTime}
            onEndTimeChange={endTime => onStateChange({ endTime })}
            onStartTimeChange={startTime => onStateChange({ startTime })}
            startTime={state.startTime}
            startTimeError={errors.startTime}
          />
        )}
      </div>

      <div className="mt-4 p-4 text-base bg-white rounded-md shadow">
        <DeviceSelect
          deviceGroups={deviceGroups}
          onDeviceGroupRemove={deviceGroup => onStateChange({ devices: filter(state.devices, device => device.deviceGroupId != deviceGroup?.id) })}
          onDeviceGroupSelect={deviceGroup => onStateChange({ devices: [...state.devices, ...filter(devices, device => device.deviceGroupId == deviceGroup?.id)] })}
          onDeviceRemove={device => onStateChange({ devices: without(state.devices, device) })}
          onDeviceSelect={device => onStateChange({ devices: [...state.devices, device] })}
          selectedDevices={state.devices}
          unselectedDevices={devices}
        />
      </div>

      {/* <div className="mt-4 p-4 text-base bg-white rounded-md shadow">
        <h2 className="h2 mb-2 pb-0">Formate</h2>
        <div className="flex flex-wrap md:flex-nowrap">
          {map(formats, format => {
            const id = `format-checkbox-${format}`

            return (
              <div className="w-full md:w-1/3" key={format}>
                <input
                  id={id}
                  onChange={e => onStateChange({ selectedFormats: e.target.checked ? [...state.selectedFormats, format] : without(state.selectedFormats, format) })}
                  type="checkbox"
                  checked={includes(state.selectedFormats, format)}
                />
                <label htmlFor={id} className="ml-1">{format.toUpperCase()}</label>
              </div>
            )
          })}
        </div>
      </div> */}

      <div className="mt-4 p-4 text-base bg-white rounded-md shadow">
        <Reoccurrence
          name={state.name}
          nameError={errors.name}
          onNameChange={name => onStateChange({ name })}
          onReoccurDailyChange={reoccurDaily => onStateChange({ reoccurDaily })}
          onReoccurWeeklyChange={reoccurWeekly => onStateChange({ reoccurWeekly })}
          onReoccurMonthlyChange={reoccurMonthly => onStateChange({ reoccurMonthly })}
          reoccurDaily={state.reoccurDaily}
          reoccurDailyError={errors.reoccurDaily}
          reoccurWeekly={state.reoccurWeekly}
          reoccurWeeklyError={errors.reoccurWeekly}
          reoccurMonthly={state.reoccurMonthly}
          reoccurMonthlyError={errors.reoccurMonthly}
        />
      </div>

      <div className="mt-4 flex justify-end">
        <input type="submit" className="button primary" value={submitLabel} disabled={!submittable} />
      </div>
    </form >
  )
}

export default ReportForm
