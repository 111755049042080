import React, { useEffect, useState } from "react"
import { filter, includes, isEmpty, map } from "lodash"

import { ReoccurringReportResource } from "api"
import FlashMessages from "FlashMessages"
import useReportFormState, { IReportFormState, IReportFormStateErrors } from "hooks/useReportFormState"

import ReportForm from "./ReportForm"

interface IReoccurringReportFormContainerProps {
  reoccurringReportId: string
}

const reportFormValidator = (state: IReportFormState) => {
  const errors = {} as IReportFormStateErrors

  if (isEmpty(state.devices)) errors.devices = "Es muss mindestens ein Gerät ausgewählt werden."
  if (!(state.reoccurWeekly || state.reoccurDaily)) errors.reoccurDaily = errors.reoccurWeekly = "Es muss mindestens ein Zeitraum gewählt werden."
  if ((state.reoccurWeekly || state.reoccurDaily || state.reoccurMonthly) && !state.name.trim()) errors.name = "Der Name für wiederkehrende Berichte darf nicht leer sein."

  return errors
}

const ReoccurringReportFormContainer: React.FC<IReoccurringReportFormContainerProps> = ({ reoccurringReportId }) => {
  const {
    devices,
    deviceGroups,
    errors,
    isLoading: isLoadingResources,
    isValid,
    selectableDevices,
    selectableFormats,
    setState,
    state
  } = useReportFormState(reportFormValidator)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [stateChanged, setStateChanged] = useState(false)
  const [isLoadingReoccurringReport, setIsLoadingReoccurringReport] = useState(true)
  const submittable = isValid && stateChanged && !isSubmitting
  const isLoading = isLoadingResources || isLoadingReoccurringReport

  const handleSubmit = async () => {
    setIsSubmitting(true)

    try {
      const { endTime, startTime, devices, ...reoccurringReport } = state

      await ReoccurringReportResource.update({
        id: reoccurringReportId,
        data: {
          ...reoccurringReport,
          deviceIds: map(devices, "id")
        }
      })
      window.location.href = "/reoccurring_reports"
    } catch (e) {
      FlashMessages.addMessage("alert", "Der wiederkehrende Bericht konnte nicht aktualisiert werden. Prüfen Sie bitte Ihre Internetverbindung.")
      setIsSubmitting(false)
      throw e
    }
  }

  const handleStateChange = (stateSlice: Partial<IReportFormState>) => {
    setState(stateSlice)
    setStateChanged(true)
  }

  useEffect(() => {
    if (isEmpty(devices)) return

    const effect = async () => {
      setIsLoadingReoccurringReport(true)

      try {
        const { deviceIds, ...reoccurringReport } = await ReoccurringReportResource.find({ id: reoccurringReportId })
        setState({ ...reoccurringReport, devices: filter(selectableDevices, device => includes(deviceIds, device.id)) })
        setIsLoadingReoccurringReport(false)
      } catch (e) {
        throw e
      }
    }

    effect()
  }, [devices])

  return (
    <ReportForm
      deviceGroups={deviceGroups}
      devices={selectableDevices}
      errors={errors}
      formats={selectableFormats}
      isLoading={isLoading}
      onStateChange={handleStateChange}
      onSubmit={handleSubmit}
      state={state}
      submitLabel="Aktualisieren"
      submittable={submittable}
    />
  )
}

export default ReoccurringReportFormContainer
