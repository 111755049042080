import { Controller } from "stimulus"

import FlashMessages from "FlashMessages"

import { isPreview } from "../../utils/turbolinks"

const FLASH_TIMEOUT_IN_MILLISECONDS = 5000
const FLASH_FADEOUT_IN_MILLISECONDS = 1000

class NewReportController extends Controller {
  protected isPermanentValue: boolean
  protected hasIsPermanentValue: boolean
  protected uniqueNameValue: string
  protected hasUniqueNameValue: boolean

  static values = {
    isPermanent: Boolean,
    uniqueName: String
  }

  connect() {
    if (isPreview()) {
      this.element.remove()
      return
    }

    if (this.hasIsPermanentValue && this.isPermanentValue) return

    setTimeout(() => this.element.classList.add("Flash--fading-out"), FLASH_TIMEOUT_IN_MILLISECONDS)
    setTimeout(() => {
      this.element.remove()

      if (this.hasUniqueNameValue) {
        FlashMessages.removeUniqueMessage(this.uniqueNameValue)
      }
    }, FLASH_TIMEOUT_IN_MILLISECONDS + FLASH_FADEOUT_IN_MILLISECONDS)
  }
}

export default NewReportController
