import { Controller } from "stimulus"

class CheckboxTableController extends Controller {
  protected checkboxTargets: HTMLInputElement[]
  protected toggleCheckboxTarget: HTMLInputElement

  static targets = ["checkbox", "toggleCheckbox"]

  toggleAllCheckboxes = (e: MouseEvent) => {
    e.stopPropagation()

    let checked = this.toggleCheckboxTarget.checked

    if ((e.target as HTMLElement).children[0] === this.toggleCheckboxTarget) {
      this.toggleCheckboxTarget.checked = !checked
      checked = !checked
    }

    for (const checkbox of this.checkboxTargets) {
      checkbox.checked = checked
    }
  }

  toggleCellCheckbox = (e: MouseEvent) => {
    e.stopPropagation()

    for (const checkbox of this.checkboxTargets) {
      if ((e.target as HTMLElement).children[0] === checkbox) {
        checkbox.checked = !checkbox.checked
        break
      }
    }
  }
}

export default CheckboxTableController
