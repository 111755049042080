import { getCache } from "lib/cache"

import { OSM_STREET_LAYER_NAME }  from "./layers"

export const DEFAULT_LAYER_NAME = OSM_STREET_LAYER_NAME

const DEFAULT_CENTER_LAT_LNG = [52.576917, 8.810927]
const DEFAULT_ZOOM = 13

const mapStorage = getCache("MAP_CONFIG")

const getCenterLatLng = () => mapStorage.get("center") || DEFAULT_CENTER_LAT_LNG
const setCenterLatLng = latLng => mapStorage.set("center", latLng)

const getLayerName = () => mapStorage.get("layerName") || DEFAULT_LAYER_NAME
const setLayerName = layerName => mapStorage.set("layerName", layerName)

const getZoom = () => mapStorage.get("zoom") || DEFAULT_ZOOM
const setZoom = zoom => mapStorage.set("zoom", zoom)

const mapConfig = {
  get centerLatLng() { return getCenterLatLng() },
  get layerName() { return getLayerName() },
  get zoom() { return getZoom() },
  setCenterLatLng,
  setLayerName,
  setZoom
}

export default mapConfig
