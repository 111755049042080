import { Controller } from "stimulus"

class HiddenContentController extends Controller {
  private contentTarget: HTMLInputElement
  private collapsedArrowTarget: HTMLInputElement
  private expandedArrowTarget: HTMLInputElement
  private collapsed = true

  static targets = ["content", "collapsedArrow", "expandedArrow"]

  toggleContent(e: InputEvent) {
    if (this.collapsed) {
      this.collapsed = false
      this.contentTarget.classList.remove("hidden")
      this.collapsedArrowTarget.classList.remove("hidden")
      this.expandedArrowTarget.classList.add("hidden")
    } else {
      this.collapsed = true
      this.contentTarget.classList.add("hidden")
      this.collapsedArrowTarget.classList.add("hidden")
      this.expandedArrowTarget.classList.remove("hidden")
    }
  }
}

export default HiddenContentController
