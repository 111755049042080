import L from "leaflet"

import { IPlace } from "api/interfaces/IPlace"

import { MAP_ENTITY_COLOR, MAP_ENTITY_COLOR_HOVER } from "../../constants"
import { editCircle, ProgressHandler } from "tracking/map/circle"
import { getPlaceEventName, triggerMapEvent } from "tracking/map/mapEvents"

class PlacePolygon {
  public place: IPlace
  private circle: L.Circle

  constructor(layer: L.FeatureGroup, place: IPlace) {
    this.place = place
    this.circle = L.circle({ lat: place.lat, lng: place.lng }, { color: MAP_ENTITY_COLOR, radius: place.radius })
      .addTo(layer)
      .bindTooltip(place.name, { sticky: true })
      .on("mouseover", () => this.highlight())
      .on("mouseout", () => this.unhighlight())
      .on("click", () => {
        triggerMapEvent(layer.map, getPlaceEventName("click"), place)
        triggerMapEvent(layer.map, getPlaceEventName("click", place.id), place)
      })
  }

  get bounds() {
    return this.circle.getBounds()
  }

  redraw() {
    this.circle.redraw()
  }

  highlight() {
    this.circle.setStyle({ color: MAP_ENTITY_COLOR_HOVER })
  }

  unhighlight() {
    this.circle.setStyle({ color: MAP_ENTITY_COLOR })
  }

  edit(progressHandler?: ProgressHandler) {
    return editCircle(this.circle, progressHandler)
  }
}

export default PlacePolygon
