import L from "leaflet"

import { IDevice } from "api/interfaces/IDevice"
import { ITelemetry } from "api/interfaces/ITelemetry"
import { getAngle, getPosition, getSpeed } from "models/telemetry"
import { TObject } from "utils"
import asyncBatchedEach from "utils/asyncBatchedEach"

import DeviceMarker from "./DeviceMarker"

const defaultGetLabel = (device: IDevice, telemetry: ITelemetry) => {
  const speed = getSpeed(telemetry)

  return speed == null
    ? device.name
    : `${device.name} (${getSpeed(telemetry)}kmh)`
}

class DeviceLayer {
  private map: L.Map
  private layer: L.FeatureGroup
  private deviceMarkers = {} as TObject<DeviceMarker>
  private deviceTelemetry = {} as TObject<ITelemetry>
  private _getLabel: (device: IDevice, telemetry: ITelemetry) => string

  constructor(map: L.Map) {
    this.map = map
    this.layer = L.featureGroup().addTo(map)
    this._getLabel = defaultGetLabel
  }

  set getLabel(_getLabel: (device: IDevice, telemetry: ITelemetry) => string) {
    this._getLabel = _getLabel
    this.updateLabels()
  }

  addDevices(devices: IDevice[]) {
    for (const device of devices) {
      this.addDevice(device)
    }
  }

  deleteDevices(deviceIds: string[]) {
    for (const deviceId of deviceIds) {
      this.deviceMarkers[deviceId]?.delete()
      delete this.deviceMarkers[deviceId]
    }
  }

  setTelemetry(telemetry: ITelemetry[]) {
    telemetry.forEach(t => {
      const deviceMarker = this.deviceMarkers[t.deviceId]

      if (deviceMarker) {
        this.updateTelemetryForDeviceMarker(deviceMarker, t)
      }

      this.deviceTelemetry[t.deviceId] = t
    })
  }

  hideDevices(deviceIds: string[]) {
    for (const deviceId of deviceIds) {
      this.deviceMarkers[deviceId]?.hide()
    }
  }

  showDevices(deviceIds: string[]) {
    for (const deviceId of deviceIds) {
      this.deviceMarkers[deviceId]?.show()
    }
  }

  fit() {
    const bounds = this.layer.getBounds()

    if (bounds.isValid()) {
      this.layer.map.fitBounds(bounds)
    }
  }

  panToDevice(deviceId: string) {
    const position = this.deviceMarkers[deviceId]?.position

    if (position) {
      this.map.setView(position, 18)
    }
  }

  highlightDevice(deviceId: string) {
    this.deviceMarkers[deviceId]?.highlight()
  }

  unhighlightDevice(deviceId: string) {
    this.deviceMarkers[deviceId]?.unighlight()
  }

  private addDevice(device: IDevice) {
    let deviceMarker = this.deviceMarkers[device.id]
    const deviceTelemetry = this.deviceTelemetry[device.id]

    if (deviceMarker) {
      deviceMarker.device = device
      return
    }

    deviceMarker = new DeviceMarker(device, this.layer)

    this.deviceMarkers[device.id] = deviceMarker

    if (deviceTelemetry) {
      this.updateTelemetryForDeviceMarker(deviceMarker, deviceTelemetry)
    }
  }

  private updateTelemetryForDeviceMarker(deviceMarker: DeviceMarker, telemetry: ITelemetry) {
    deviceMarker
      .moveTo(getPosition(telemetry))
      .rotate(getAngle(telemetry))
      .setLabel(this._getLabel(deviceMarker.device, telemetry))
  }

  private updateLabels() {
    for (const [deviceId, deviceMarker] of Object.entries(this.deviceMarkers)) {
      const telemetry = this.deviceTelemetry[deviceId]

      if (telemetry) {
        deviceMarker.setLabel(this._getLabel(deviceMarker.device, telemetry))
      }
    }
  }
}

export default DeviceLayer
