import { cloneDeep, debounce, noop } from "lodash"
import { MAP_ENTITY_COLOR } from "./constants"

export type ProgressHandler = (attributes: CircleAttributes) => any
export interface CircleAttributes {
  area: number
  circle: L.Circle
  coordinates: { lat: number, lng: number }
  isValid: boolean
  radius: number
}

const addCircleEventHandlers = (circle: L.Circle, progressHandler: (...args: any[]) => void) => {
  const debouncedProgressHandler = debounce(progressHandler, 100)

  circle.on("editable:editing", debouncedProgressHandler)

  return () => {
    circle.off("editable:editing", debouncedProgressHandler)
  }
}

const getLeafletChangeHandler = (circle: L.Circle, onProgress?: ProgressHandler) => {
  if (!onProgress) return noop

  return () => {
    const radius = circle.getRadius()
    const coordinates = circle.getLatLng()

    onProgress({
      area: 2 * Math.PI * radius,
      circle,
      coordinates,
      isValid: radius > 0,
      radius
    })
  }
}

export const drawCircle = (map: L.Map, onProgress?: ProgressHandler) => {
  const circle = map.editTools.startCircle.call(map.editTools, null, { radius: 0 })
  const progressHandler = getLeafletChangeHandler(circle, onProgress)
  const removeEventHandlers = addCircleEventHandlers(circle, progressHandler)

  circle.setStyle({ color: MAP_ENTITY_COLOR })

  // Set the progress handler to it's initial state.
  progressHandler()

  return (reset: boolean = true) => {
    removeEventHandlers()
    circle.disableEdit()

    if (reset) {
      circle.removeFrom(map)
    }
  }
}

export const editCircle = (circle: L.Circle, onProgress?: ProgressHandler) => {
  const progressHandler = getLeafletChangeHandler(circle, onProgress)
  const removeEventHandlers = addCircleEventHandlers(circle, progressHandler)
  const initialCoordinates = cloneDeep(circle.getLatLng())
  const initialRadius = circle.getRadius()

  // Pass the initial progress of the existing circle to the progress handler.
  progressHandler()

  circle.enableEdit()

  return (reset: boolean = false) => {
    removeEventHandlers()
    circle.disableEdit()

    if (reset) {
      circle.setLatLng(initialCoordinates)
      circle.setRadius(initialRadius)
    }
  }
}
