import { AxiosRequestConfig } from "axios"

import { IOrder } from "api/interfaces/IOrder"
import { ICart, IShop } from "api/interfaces/IShop"
import Resource from "api/Resource"
import { formatDate, formatParameterDate } from "utils/date"

interface Icart {
  id: string
  options?: AxiosRequestConfig
}

interface IDeliveryDateValidity {
  deliveryDate: Date
  id: string
}

interface IupdateLineItem {
  deliveryDate: Date
  shopId: string
  productId: string
  quantity: string
}

interface Iorder {
  additionalInformation?: string
  checksum: string
  desiredDeliveryDate: string
  id: string
}

interface IallOrders {
  id: string
  options?: AxiosRequestConfig
}
class ShopResource extends Resource<IShop> {
  get resourcePath() { return "/api/shops" }

  allOrders = ({ id, options }: IallOrders): Promise<IOrder[]> => this.get(`${this.resourcePath}/${id}/orders`, options)

  cart = ({ id, options }: Icart): Promise<ICart> => this.get(`${this.resourcePath}/${id}/cart`, options)

  deliveryDateValidity = ({ deliveryDate, id }: IDeliveryDateValidity): Promise<any> =>
    this.get(`${this.resourcePath}/${id}/delivery_date_validity`, { params: { deliveryDate: formatParameterDate(deliveryDate) } })

  updateLineItem = ({ deliveryDate, shopId, productId, quantity }: IupdateLineItem): Promise<ICart> =>
    this.patch(`${this.resourcePath}/${shopId}/line_items`, { deliveryDate: formatDate(deliveryDate), quantity, productId })

  order = ({ additionalInformation, checksum, desiredDeliveryDate, id }: Iorder) => this.post(`${this.resourcePath}/${id}/order`, { additionalInformation, checksum, desiredDeliveryDate })
}

export default ShopResource
