import { includes, last } from "lodash"
import React, { useMemo } from "react"
import Select, { components, OptionProps } from "react-select"

export interface IOption {
  value: string
  label: string
}

interface IAutocompleteProps {
  className?: string
  defaultValue?: string
  id: string
  isClearable?: boolean
  isMulti?: boolean
  label?: string
  onChange?: (option: IOption | IOption[]) => any
  options: IOption[]
  value?: string | string[]
}

const SELECT_ALL_VALUE = "____AUTOCOMPLETE_SELECT_ALL"

const IconOption = (props: OptionProps<any, any> & { data: { color?: string, icon?: React.ReactNode } }) => (
  <components.Option {...props}>
    {props.data.icon}
    {props.label}
    {props.data.color && <div className="ml-1 w-4 h-4 rounded-full" style={{ background: props.data.color }} />}
  </components.Option>
)

const Autocomplete: React.FunctionComponent<IAutocompleteProps> = ({ className, defaultValue, id, isClearable, isMulti, label, onChange, options, value }) => {
  const defaultOption = useMemo(() => (
    defaultValue == null ? undefined : options.find(option => option.value === defaultValue)
  ), [defaultValue, options])
  const valueOption = useMemo(() => {
    if (value == null) return null
    if (isMulti) return options.filter(option => includes(value, option.value))
    return options.find(option => option.value === value)
  }, [options, value])

  const showSelectAll = isMulti && (valueOption as IOption[]).length < options.length
  const selectableOptions = showSelectAll ? [{ value: SELECT_ALL_VALUE, label: "Alle auswählen" }, ...options] : options

  const handleChange = (value: IOption | IOption[]) => {
    if (Array.isArray(value)) {
      if (last(value)?.value === SELECT_ALL_VALUE) {
        onChange(options)
        return
      }
    }

    onChange(value)
  }

  return (
    <>
      <label className="text-sm font-semibold" htmlFor={id}>{label}</label>
      <Select
        components={{ Option: IconOption }}
        className={className}
        isClearable={isClearable}
        defaultValue={defaultOption}
        onChange={handleChange}
        options={selectableOptions}
        noOptionsMessage={() => "Kein Ergebnis"}
        placeholder="Auswählen..."
        id={id}
        isMulti={isMulti}
        value={valueOption}
        theme={theme => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary: "#059669"
          }
        })}
        styles={{
          option: base => ({
            ...base,
            display: "flex",
            alignItems: "center"
          })
        }}

      />
    </>
  )
}

export default Autocomplete
