import { ITelemetry } from "../api/interfaces/ITelemetry"

export enum TelemetryElement {
  ALTITUDE = "alt",
  ANALOG_INPUT_PREFIX = "ai",
  ANGLE = "ang",
  AXIS_X = "x",
  AXIS_Y = "y",
  AXIS_Z = "z",
  BARCODE_ID = "barcode",
  DIGITAL_INPUT_PREFIX = "di",
  DIGITAL_OUTPUT_PREFIX = "do",
  EVENT_ID = "eId",
  EXTERNAL_VOLTAGE = "V",
  IGNITION = "ign",
  INTERNAL_VOLTAGE = "VInt",
  MOTION = "move",
  NUMBER_OF_SATELLITES = "sats",
  TOTAL_ODOMETER = "odo",
  TRIP_ODOMETER = "odoTrip",
  PRIORITY = "prio",
  RSSI = "rssi",
  SLEEP_MODE = "sleep",
  SPEED = "kmh",
  ENGINE_TEMPERATURE = "tempEngine",
  TEMPERATURE_PREFIX = "temp",
  UNIQUE_ID = "uid",
  VIN = "vin"
}

export const getAngle = (telemetry: ITelemetry): number => telemetry.elements[TelemetryElement.ANGLE] as number
export const getSpeed = (telemetry: ITelemetry): number => telemetry.elements[TelemetryElement.SPEED] as number
export const getPosition = (telemetry: ITelemetry): L.LatLngExpression => [parseFloat(telemetry.lat), parseFloat(telemetry.lng)]
export const getGeoJSONPosition = (telemetry: ITelemetry): number[] => [parseFloat(telemetry.lng), parseFloat(telemetry.lat)]
