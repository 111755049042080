import createMap from "./createMap"
import { getLayers, layerHasTileMargins } from "./layers"
import mapConfig, { DEFAULT_LAYER_NAME } from "./mapConfig"

const TILE_MARGINS_CLASS_NAME = "adapted-tile-margins"

const createMapFromConfig = (elementId: string | HTMLElement) => {
  const layers = getLayers()
  const layerName = layers[mapConfig.layerName]
    ? mapConfig.layerName
    : DEFAULT_LAYER_NAME
  const layer = layers[layerName]
  const element = typeof elementId === "string" ? document.getElementById(elementId) : elementId

  const adaptTileMargins = (layerName: string) => {
    // Google Maps layers have an ugly margin, which is removed by this fix.
    if (layerHasTileMargins(layerName)) {
      element.classList.add(TILE_MARGINS_CLASS_NAME)
    } else {
      element.classList.remove(TILE_MARGINS_CLASS_NAME)
    }
  }

  adaptTileMargins(layerName)

  return createMap(element, {
    center: mapConfig.centerLatLng,
    layer,
    layers,
    onCenterChange: latLng => mapConfig.setCenterLatLng(latLng),
    onLayerChange: layerName => {
      mapConfig.setLayerName(layerName)
      adaptTileMargins(layerName)
    },
    onZoomChange: zoom => mapConfig.setZoom(zoom),
    zoom: mapConfig.zoom
  })
}

export default createMapFromConfig
