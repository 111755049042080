import { AxiosRequestConfig } from "axios"

import { IUser } from "../interfaces/IUser"
import Resource from "../Resource"

class UserResource extends Resource<IUser> {
  get resourcePath() { return "/api/users" }

  current = (config?: AxiosRequestConfig): Promise<IUser> => this.get("/api/identity", config)
}

export default UserResource
