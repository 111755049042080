import { ILocation } from "../interfaces/ILocation"
import Resource from "../Resource"

class LocationResource extends Resource<ILocation> {
  get resourcePath() { return "/api/location" }

  reverseGeocode = (lat: number, lng: number) =>
    this.get(`${this.resourcePath}/reverse_geocode`, { params: { lat, lng } })
}

export default LocationResource
