import { useEffect, useMemo, useState } from "react"

import useKeyListener from "./useKeyListener"

const modalStack = []

const useModal = ({ onClose }: { onClose?: () => void } = {}) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useMemo(() => ({}), [])

  useKeyListener("Escape", () => close(false))

  function close (force = true) {
    if (!isOpen) return

    if (modalStack[modalStack.length - 1] !== ref && !force) {
      return
    }

    while (modalStack.pop() !== ref && modalStack.length > 0) {}

    if (modalStack.length === 0) {
      document.body.classList.remove("overflow-hidden")
    }

    setIsOpen(false)

    if (onClose) {
      onClose()
    }
  }

  const open = () => {
    if (isOpen) return

    document.body.classList.add("overflow-hidden")
    modalStack.push(ref)
    setIsOpen(true)
  }

  useEffect(() => close, [])

  return {
    close,
    isOpen,
    open
  }
}

export default useModal
