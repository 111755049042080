import { addDays, endOfDay, startOfDay, subDays } from "date-fns"

import { TimePeriodValue } from "./TimePeriodSelector"
import { ITimePeriod } from "./types"

export const resolveTimePeriod = ({ endTime, startTime, value }: ITimePeriod): { endTime: Date, startTime: Date } => {
  switch (value) {
    case TimePeriodValue.CUSTOM: return { endTime: new Date(endTime), startTime: new Date(startTime) }
    case TimePeriodValue.TODAY: return today()
    case TimePeriodValue.YESTERDAY: return yesterday()
    case TimePeriodValue.TWO_DAYS_AGO: return twoDaysAgo()
    case TimePeriodValue.THREE_DAYS_AGO: return threeDaysAgo()
    case TimePeriodValue.LAST_WEEK: return lastWeek()
    case TimePeriodValue.ONE_WEEK_AGO: return oneWeekAgo()
    default: return today()
  }
}

const today = () => {
  const date = new Date()

  return {
    endTime: endOfDay(date),
    startTime: startOfDay(date),
  }
}

const yesterday = () => {
  const date = subDays(new Date(), 1)

  return {
    endTime: endOfDay(date),
    startTime: startOfDay(date),
  }
}

const twoDaysAgo = () => {
  const date = subDays(new Date(), 2)

  return {
    endTime: endOfDay(date),
    startTime: startOfDay(date),
  }
}

const threeDaysAgo = () => {
  const date = subDays(new Date(), 3)

  return {
    endTime: endOfDay(date),
    startTime: startOfDay(date),
  }
}

const lastWeek = () => {
  const date = new Date()

  return {
    endTime: endOfDay(date),
    startTime: startOfDay(subDays(date, 7))
  }
}

const oneWeekAgo = () => {
  const date = new Date()

  return {
    endTime: endOfDay(subDays(date, 8)),
    startTime: startOfDay(subDays(date, 14))
  }
}
